import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, connectAuthEmulator, initializeAuth } from 'firebase/auth';

const configs = JSON.parse(`${process.env.REACT_APP_FIREBASE_CONFIG}`);
const firebaseApp = initializeApp(configs);

const useEmulator = `${process.env.REACT_APP_FIREBASE_USE_EMULATOR}`.trim().toLowerCase() === 'true';

const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence
});

if (useEmulator) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

export { auth };