import React, {useMemo} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {MatchDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import MatchGroup, {groupMatchesByRound} from './MatchGroup';
import {CompletedMatchScore, groupConfigs} from './InProgressTournament';
import CompletedMatchCard from './CompletedMatchCard';

interface Props {
    matches: Array<MatchDTO>;
    scores: Array<CompletedMatchScore>;
    handleRefreshCompletedMatches?: () => Promise<void>;
}

const CompletedMatches = (props: Props): JSX.Element => {
  const { matches, scores, handleRefreshCompletedMatches } = props;
  const matchGroups = useMemo(() => groupMatchesByRound(matches), [matches]);

  return <React.Fragment>
    <Typography
      sx={{marginY: 2}} variant="h5">
            Completed Matches
    </Typography>
    <Box padding={2}
      sx={{border: 1, borderStyle: 'dashed', borderColor: groupConfigs.completedGroupConfigSet.borderColor}}>
      {
        matches.length === 0
          ? <Typography textAlign='center'>There are no completed matches.</Typography>
          : matchGroups.map((group, index) => <MatchGroup
            defaultClosed
            key={group.round}
            noDivider={index === (matchGroups.length - 1)}
            round={group.round}
            roundName={group.roundName}
            isBo5={group.isBo5}
            matchCards={group.matches.map(match => {
              const matchScore = scores.find(score => score.matchId === match.id);
              if (!matchScore) {
                return<></>;
              }
              return <Grid key={match.id} item>
                <CompletedMatchCard
                  entrantIds={match.entrantIds}
                  match={match}
                  matchScore={matchScore}
                  handleRefreshCompletedMatches={handleRefreshCompletedMatches}
                />
              </Grid>;
            })}
          />)
      }
    </Box>
  </React.Fragment>;
};

export default CompletedMatches;