import React from 'react';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import Login from './pages/Login';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Tournaments from './pages/Tournaments';
import RequiresAuth from './RequiresAuth';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import ContextWrapper from './context/ContextWrapper';
import Tournament from './pages/Tournament';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import OAuthRouteHandler from './api/auth/oauth/OAuthRouteHandler';
import BetaGate from './components/BetaGate';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#63b8ff',
      main: '#0989e3',
      dark: '#005db0',
      contrastText: '#000',
    },
    secondary: {
      main: '#4db6ac',
      light: '#82e9de',
      dark: '#00867d',
      contrastText: '#000',
    },
  },
});

function App() {

  return (

    <ThemeProvider theme={theme}>
      <ContextWrapper>
        <BrowserRouter>
          <ResponsiveAppBar/>
          <BetaGate>
            <Routes>
              <Route path='/login' element={<Login/>}/>
              <Route path='/tournaments' element={<RequiresAuth><Tournaments/></RequiresAuth>}/>
              <Route path='/' element={<Navigate to='/tournaments' replace/>}/>
              <Route path='/tournaments/:id' element={<RequiresAuth><Tournament/></RequiresAuth>}/>
              <Route path='/oauth' element={<OAuthRouteHandler/>}/>
            </Routes>
          </BetaGate>
        </BrowserRouter>
      </ContextWrapper>
      <CssBaseline enableColorScheme/>
    </ThemeProvider>
  );
}

export default App;
