import React, {useContext, useEffect, useState} from 'react';
import tcsApi from '../api/tc-server/api';
import {TournamentDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {AuthContext} from './AuthContext';

interface ManagedTournamentsContextInterface {
    loading: boolean,
    tournaments: Array<TournamentDTO>,
    refreshTournaments:() => Promise<void>
}

const ManagedTournamentsContext = React.createContext<ManagedTournamentsContextInterface>({} as ManagedTournamentsContextInterface);

interface Props {
    children?: React.ReactNode;
}

const ManagedTournamentsProvider = ({children}:Props):JSX.Element => {

  const { loggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [managedTournaments, setManagedTournaments] = useState<Array<TournamentDTO>>();

  const fetchManagedTournaments = async () => setManagedTournaments(await tcsApi.tournaments.getManaged());

  useEffect(() => {
    const effect = async () => {
      setLoading(true);
      if (loggedIn) {
        await fetchManagedTournaments();
        setLoading(false);
      } else {
        setManagedTournaments([]);
      }
    };

    effect();
  }, [loggedIn]);

  const providerValue:ManagedTournamentsContextInterface = {
    loading,
    tournaments: managedTournaments ?? [],
    refreshTournaments: fetchManagedTournaments
  };


  return <ManagedTournamentsContext.Provider value={providerValue}>
    {children}
  </ManagedTournamentsContext.Provider>;
};

export {ManagedTournamentsContext, ManagedTournamentsProvider};