import React from 'react';
import {SSBUStage, ssbuStages} from '../types/ssbu/ssbu_stages';
import {Box, Card, CardContent, CardMedia, Grid, Switch, Tooltip, Typography} from '@mui/material';

interface Props {
    disabled?: boolean,
    stage: SSBUStage,
    showToggle: boolean,
    selected?: boolean,
    toggleSelected?: () => void
}

const StageTab = (props: Props): JSX.Element => {
  const {disabled = false, stage, showToggle, selected = false, toggleSelected} = props;
  const stageData = ssbuStages[stage];

  const cursorValue = showToggle ? 'pointer' : '';

  return <Tooltip title={stageData.name}>
    <Grid
      item
      sm={6} md={4} lg={3}
    >
      <Card
        sx={{
          opacity: disabled ? '.5': '1',
          display: 'flex',
          cursor: cursorValue,
          width: 220,
          height: '100%',
          borderWidth: '1',
          borderStyle: 'solid',
          borderColor: 'gray'
        }}
        onClick={toggleSelected}
      >
        <Box sx={{display: 'flex', width: '50%', flexDirection: 'column'}}>
          <CardContent sx={{flex: 1}}>
            <Typography
              sx={{textAlign: 'center'}}><b>{stageData.abbreviatedName || stageData.name}</b></Typography>
            {showToggle && <Switch checked={selected}/>}
          </CardContent>
        </Box>
        <CardMedia
          sx={{flex: 1, width: '50%'}}
          component='img'
          src={stageData.image}
          alt={stageData.name}
        />
      </Card>
    </Grid>
  </Tooltip>;
};

export default StageTab;