import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@mui/material';

interface Props {
  bo5Threshold: number;
  handleUpdateBo5Threshold: (val: number) => void;
  stageFirst: boolean;
  handleUpdateStageFirst: (val: boolean) => void;
  readOnly?: boolean;
}

interface Bo5CriteriaValue {
    key: number,
    value: string
}

interface CounterpickStyleValue {
  key: string,
  value: string
}

const bo5CriteriaValues:Array<Bo5CriteriaValue> = [
  { key: 0, value: 'Never (All matches are Best of 3)' },
  { key: 999999, value: 'Always (All matches are Best of 5)' },
  { key: 3, value: 'Top 3 (Finals Only)' },
  { key: 4, value: 'Top 4 (Semifinals and Finals)' },
  { key: 5, value: 'Top 5' },
  { key: 6, value: 'Top 6' },
  { key: 8, value: 'Top 8' },
  { key: 12, value: 'Top 12' },
  { key: 16, value: 'Top 16' },
  { key: 24, value: 'Top 24' },
  { key: 32, value: 'Top 32' },
  { key: 48, value: 'Top 48' },
  { key: 64, value: 'Top 64' },
  { key: 128, value: 'Top 128' },
  { key: 256, value: 'Top 256' },
  { key: 512, value: 'Top 512' }
];

const counterpickStyleValues:Array<CounterpickStyleValue> = [
  { key: 'true', value: 'Stage First (Default)' },
  { key: 'false', value: 'Character First' },
];

const RulesetAdditionalSettings = (props:Props):JSX.Element => {

  const { bo5Threshold, handleUpdateBo5Threshold, stageFirst, handleUpdateStageFirst, readOnly = false } = props;

  return <Paper sx={{ margin: 1, paddingY: 3, paddingX: 1}} elevation={6}>
    <Paper elevation={2} sx={{marginX: 2, padding: 1, paddingTop: 3}}>
      <Typography marginLeft={2} marginBottom={1}><b>Additional Settings:</b></Typography>
      <Grid columns={2} spacing={6} container alignItems='stretch' paddingX={3} paddingY={1}>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <InputLabel>When to begin Best of 5?</InputLabel>
            <Select
              name="When to begin Best of 5?"
              value={bo5Threshold ?? '' }
              onChange={(event) => handleUpdateBo5Threshold(event.target.value as number)}
              label="Best of 5 Criteria"
              disabled={readOnly}
            >
              {bo5CriteriaValues.map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <InputLabel>Counterpick Style:</InputLabel>
            <Select
              name="Counterpick Style"
              value={stageFirst ? 'true' : 'false' }
              onChange={(event) => handleUpdateStageFirst(event.target.value === 'true')}
              label="Counterpick Style"
              disabled={readOnly}
            >
              {counterpickStyleValues.map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

    </Paper>
  </Paper>;
};

export default RulesetAdditionalSettings;
