import React, { useState } from 'react';
import {Autocomplete, Button, Grid, TextField} from '@mui/material';

export interface Template {
    name: string,
    owningUserId?: number
}

interface Props {
    availableTemplates: Array<Template>,
    handleApplyTemplate: () => void,
    handleUpdateSelectedTemplate: (template: Template | undefined) => void,
    selectedTemplate?: Template
}

const TemplateApplicationManager = (props:Props):JSX.Element => {
  const { availableTemplates, handleApplyTemplate, handleUpdateSelectedTemplate, selectedTemplate } = props;
  const [inputValue, setInputValue] = useState('');

  return <Grid container direction='row'>
    <Grid item>
      <Autocomplete
        fullWidth
        disablePortal
        getOptionLabel={(option) => `${option.name}`}
        groupBy={(option) => option.owningUserId ? 'My Templates' : 'Global Templates'}
        options={availableTemplates}
        sx={{ marginLeft: 2, width: 300 }}
        renderInput={(params) => <TextField {...params} label="Load From Template" />}
        inputValue={inputValue}
        value={selectedTemplate || null}
        onChange={(event, newVal) => handleUpdateSelectedTemplate(newVal || undefined)}
        onInputChange={(event, newVal) => setInputValue(newVal)}
      />
    </Grid>
    <Grid item>
      <Button
        onClick={handleApplyTemplate}
        disabled={!selectedTemplate}
        sx={{ marginLeft: 1, marginTop: 1 }}
      >
                Apply Template
      </Button>
    </Grid>
  </Grid>;
};

export default TemplateApplicationManager;