import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from '@mui/material';
import tcsApi from '../api/tc-server/api';

interface Props {
    handleClose: () => void;
    open: boolean;
    matchId: number;
    isComplete: boolean;
    handleRefreshCompletedMatches: () => Promise<void>;
}

const ConfirmResetMatchModal = (props:Props):JSX.Element => {
  const [resetInProgress, setResetInProgress] = useState(false);
  const { handleClose, handleRefreshCompletedMatches, open, matchId, isComplete } = props;

  const resetMatch = async () => {
    try {
      setResetInProgress(true);
      await tcsApi.matches.reset(matchId);
      await handleRefreshCompletedMatches();
    } catch (err) {
      console.error('failed to reset bracket', err);
    }
    setResetInProgress(false);
    handleClose();
  };

  return <Dialog maxWidth={'sm'} open={open} onClose={resetInProgress ? undefined : handleClose}>
    <DialogTitle>Reset the progress of this match?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
                This will fully reset results for this match. {isComplete && 'Additionally, any matches that depend on the result of this match will also be reset.'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button disabled={resetInProgress} onClick={handleClose}>Cancel</Button>
      <Button disabled={resetInProgress} onClick={resetMatch}>Reset Match</Button>
    </DialogActions>
    {resetInProgress && <LinearProgress />}
  </Dialog>;
};

export default ConfirmResetMatchModal;
