import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {RulesetDTO, StationGroupDTO, TournamentDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import ManageRuleset from './ManageRuleset';
import ManageStations from './ManageStations';
import LaunchPane from './LaunchPane';

interface Props {
    tournament: TournamentDTO,
    storedRuleset?: RulesetDTO,
    storedStationGroup?: StationGroupDTO,
    loading: boolean,
    handleUpdateStoredRuleset: (val:RulesetDTO) => void,
    handleUpdateStoredStationGroup: (val:StationGroupDTO) => void
}

interface ConfigurationStep {
    completed: boolean,
    disabled?: boolean,
    label: string,
    type: ConfigurationStepType
}

type ConfigurationStepType = 'RULESET' | 'STATIONS' | 'LAUNCH';

const PreLaunchTournament = (props:Props):JSX.Element => {
  const { tournament, storedRuleset, storedStationGroup, loading, handleUpdateStoredStationGroup, handleUpdateStoredRuleset } = props;

  const [currentStep, setCurrentStep] = useState(0);

  const determineStep = useCallback(() => {
    if (!storedRuleset) {
      return 0;
    } else if (!storedStationGroup) {
      return 1;
    } else {
      return 2;
    }
  }, [storedRuleset, storedStationGroup]);

  const updateStoredRuleset = useCallback((val:RulesetDTO) => {
    handleUpdateStoredRuleset(val);
    setCurrentStep(determineStep());
  }, [determineStep, handleUpdateStoredRuleset]);

  const updateStoredStationGroup = useCallback((val:StationGroupDTO) => {
    handleUpdateStoredStationGroup(val);
    setCurrentStep(determineStep());
  }, [determineStep, handleUpdateStoredStationGroup]);

  const steps:Array<ConfigurationStep> = useMemo(() => ([
    { completed: Boolean(storedRuleset), label: 'Configure Ruleset', type: 'RULESET' },
    { completed: Boolean(storedStationGroup), label: 'Configure Stations', type: 'STATIONS' },
    { completed: false, label: 'Launch Tournament', type: 'LAUNCH', disabled: Boolean(!storedRuleset || !storedStationGroup) }
  ]), [storedRuleset, storedStationGroup]);

  useEffect(() => {
    if (!loading) {
      setCurrentStep(determineStep());
    }
  }, [determineStep, loading]);

  const stepBody = useMemo(() => {
    const { type } = steps[currentStep];
    switch (type) {
    case 'RULESET':
      return  <ManageRuleset
        loading={loading}
        storedRuleset={storedRuleset}
        tournamentId={tournament.id ?? 0}
        updateStoredRuleset={updateStoredRuleset}
      />;
    case 'STATIONS':
      return <ManageStations
        storedStationGroup={storedStationGroup}
        tournamentId={tournament.id ?? 0}
        updateStoredStationGroup={updateStoredStationGroup}
      />;
    case 'LAUNCH':
      return <LaunchPane tournamentId={tournament.id ?? 0}/>;
    }
  }, [currentStep, loading, steps, storedRuleset, storedStationGroup, tournament.id, updateStoredRuleset, updateStoredStationGroup]);

  return <React.Fragment>
    <Stepper sx={{ margin: 3 }} nonLinear activeStep={currentStep}>
      {steps.map((step, index) => <Step key={index} completed={step.completed} disabled={step.disabled} sx={ step.disabled ? { cursor:'not-allowed'} : undefined}>
        <StepButton onClick={() => setCurrentStep(index)}>
          <StepLabel
            StepIconProps={{ color: index === currentStep ? 'primary' : 'disabled'}}
            StepIconComponent={
              step.completed
                ? (props) => <CompletedStepIcon active={index === currentStep} {...props} />
                : undefined
            }>
            {step.label}
          </StepLabel>
        </StepButton>
      </Step>)}
    </Stepper>
    {stepBody}
  </React.Fragment>;
};

const CompletedStepIcon = ({ active }: { active: boolean }):JSX.Element => <TaskAltIcon color={active ? 'primary' : undefined} />;

export default PreLaunchTournament;
