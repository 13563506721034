import React, {useRef} from 'react';
import { Button, Grid, Paper } from '@mui/material';
import StationCard from './StationCard';
import AddStationCard from './AddStationCard';
import {useHover} from 'usehooks-ts';

interface Props {
    handleUpdateStations: (stations:Array<string>) => void,
    stations: Array<string>
}

const StationGroup = (props:Props):JSX.Element => {
  const { handleUpdateStations, stations } = props;

  const addStation = () => handleUpdateStations([...stations, '']);
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const updateValue = (index:number, val:string) => {
    const newStations = [...stations];
    newStations[index] = val;
    handleUpdateStations(newStations);
  };

  const deleteStation = (indexToDelete:number) => {
    const updatedStations = stations.filter((station, index) => index !== indexToDelete);
    handleUpdateStations(updatedStations);
  };

  const stationsEmpty = stations.length < 1;

  return <Paper
    ref={hoverRef}
    onClick={stationsEmpty ? addStation : undefined}
    sx={{ cursor: stationsEmpty ? 'pointer' : '', margin: 1, paddingY: 3, paddingX: 1 }}
    elevation={7}
  >
    {
      !stationsEmpty
        ? <Grid
          paddingX={4}
          spacing={6}
          container
          alignItems='center'
          justifyContent='flex-start'
        >
          {stations.map((station, index) => <StationCard
            key={index}
            index={index}
            stationName={station}
            onDelete={() => deleteStation(index)}
            onUpdate={updateValue}
          />)}
          <AddStationCard handleAddStation={addStation} visible={isHovered} />
        </Grid>
        : <div style={{ display: 'flex', justifyContent: 'center'}}>
          <Button onClick={addStation}>Add Station</Button>
        </div>
    }
  </Paper>;
};

export default StationGroup;
