import { OAuthCredential } from 'firebase/auth';
import { env } from '../../../config/env';
import { authApi } from '../tcAuth';


export type OAuthProviderName = 'twitter' | 'startgg';

export interface OAuthProvider {
    name:OAuthProviderName,
    initiateOauthFlow: () => Promise<void>,
    completeOauthFlow: (code: string) => Promise<OAuthCredential>
    // connectOauth: () => Promise<void>,
    // disconnectOauth: () => Promise<void>
}

export default (providerName:OAuthProviderName):OAuthProvider => {
  switch (providerName) {
  case 'startgg': return startggOAuthProvider;
  default: throw Error(`Unsupported provider type: ${providerName}`);
  }
};

const startggOAuthProvider:OAuthProvider = {
  name: 'startgg',
  initiateOauthFlow: async () => {
    console.log('redirect url: ', env.oauth.startgg.redirectUrl);
    const {data:response} = await authApi.get(`initiate-oauth?provider=startgg&isMobile=false&callback_url=${env.oauth.startgg.redirectUrl}`);
    const authUrl = `https://start.gg/oauth/authorize?response_type=code&client_id=${response.clientId}&scope=${response.scopes.join('%20')}&redirect_uri=${encodeURI(response.redirectUri)}&isMobile=false`;
    window.open(authUrl, '_self');
  },
  completeOauthFlow: async (code) => {
    const { data } = await authApi.get(`process-provider-authentication?provider=startgg&code=${code}&isMobile=false&callback_url=${env.oauth.startgg.redirectUrl}`);
    return data.loginToken;
  },
/*    connectOauth: async () => {
        const {data:response} = await authApi.get('initiate-oauth?provider=startgg');
        const authUrl = `http://start.gg/oauth/authorize?response_type=code&client_id=${response.clientId}&scope=${response.scopes.join('%20')}&redirect_uri=${encodeURI(response.redirectUri)}`;
        const authResponse = await AuthSession.startAsync({ authUrl });
        const firebaseToken = await auth.currentUser?.getIdToken();

        await authApi.post(`connect-external?provider=startgg&code=${authResponse.params.code}`, {}, {
            headers: {
                'auth-token': firebaseToken ?? ''
            }
        });


    },
    disconnectOauth: async () => {
        const firebaseToken = await auth.currentUser?.getIdToken();
        await authApi.post('disconnect-external?provider=startgg', {}, {
            headers: {
                'auth-token': firebaseToken ?? ''
            }
        });
    }*/
};
