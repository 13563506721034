import React from 'react';
import {Dialog, DialogTitle} from '@mui/material';
import { StationGroupDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import ManageStations from '../components/ManageStations';

interface Props {
    handleClose: () => void
    open: boolean,
    tournamentId: number,
    storedStationGroup?: StationGroupDTO,
    updateStoredStationGroup: (val:StationGroupDTO) => void
}

const ManageStationsModal = (props:Props):JSX.Element => {
  const { handleClose, open, storedStationGroup, updateStoredStationGroup, tournamentId } = props;
  return <Dialog PaperProps={{ sx: { width: '60%' }}} maxWidth={false} open={open} onClose={handleClose}>
    <DialogTitle>Manage Ruleset</DialogTitle>
    <ManageStations
      tournamentId={tournamentId}
      storedStationGroup={storedStationGroup}
      updateStoredStationGroup={updateStoredStationGroup}
    />
  </Dialog>;
};

export default ManageStationsModal;
