import React, {useMemo} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import { MatchDTO, RulesetDTO, StationGroupDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import MatchGroup, {groupMatchesByRound} from './MatchGroup';
import {groupConfigs, MatchStateResponse} from './InProgressTournament';
import InProgressMatchCard from './InProgressMatchCard';

interface Props {
    matches: Array<MatchDTO>,
    matchStates: Array<MatchStateResponse>
    storedStationGroup?: StationGroupDTO,
    stationsInUse: Array<string>,
    updateMatch: (val: MatchDTO) => void,
    handleRefreshCompletedMatches: () => Promise<void>;
    handleMatchComplete: (matchId: number) => Promise<void>;
    ruleset: RulesetDTO;
}

const InProgressMatches = (props: Props): JSX.Element => {
  const {matches, matchStates, storedStationGroup, stationsInUse, updateMatch, handleRefreshCompletedMatches, handleMatchComplete, ruleset} = props;
  const matchGroups = useMemo(() => groupMatchesByRound(matches), [matches]);

  return <React.Fragment>
    <Typography
      sx={{ marginY: 2 }} variant="h5">
            In-Progress Matches
    </Typography>
    <Box padding={2} sx={{ border: 1, borderStyle: 'dashed', borderColor: groupConfigs.inProgressGroupConfigSet.borderColor }}>
      {
        matches.length === 0
          ? <Typography textAlign='center'>There are no in-progress matches.</Typography>
          : matchGroups.map((group, index) => <MatchGroup
            key={group.round}
            noDivider={index === (matchGroups.length - 1)}
            round={group.round}
            roundName={group.roundName}
            isBo5={group.isBo5}
            matchCards={group.matches.map(match => {
              const matchState = matchStates.find(matchState => matchState.matchId === match.id);
              return matchState ? <Grid key={match.id} item>
                <InProgressMatchCard
                  entrantIds={match.entrantIds}
                  match={match}
                  matchState={matchState}
                  stations={storedStationGroup?.stationNames ?? []}
                  stationsInUse={stationsInUse}
                  updateMatch={updateMatch}
                  handleRefreshCompletedMatches={handleRefreshCompletedMatches}
                  handleMatchComplete={handleMatchComplete}
                  ruleset={ruleset}
                />
              </Grid> : <></>;
            })}
          />)
      }
    </Box>
  </React.Fragment>;
};

export default InProgressMatches;