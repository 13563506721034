import React, { useState } from 'react';
import { EntrantDetailsDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import { Link, Typography, Box, Tooltip } from '@mui/material';
import EntrantSummaryModal from './EntrantSummaryModal';
import { Cable, HowToReg } from '@mui/icons-material';
import defaultProfilePic from '../assets/default_profile_pic.jpeg';

export type MatchConnectionState = 'CONNECTED' | 'DISCONNECTED';

interface Props {
  entrantDetails?: EntrantDetailsDTO;
  connectionState?: MatchConnectionState;
}

const CardEntrantRow = (props: Props): JSX.Element => {
  const { connectionState, entrantDetails } = props;
  const [expanded, setExpanded] = useState(false);

  if (!entrantDetails) {
    return <Typography color='gray' textAlign='center'>TBD</Typography>;
  }

  if (!entrantDetails.name) {
    console.error('Missing name from entrant: ', entrantDetails);
    return <Typography color='gray' textAlign='center'>Unknown</Typography>;
  }

  const truncatedName = entrantDetails.name.length > 20 ? `${entrantDetails.name.slice(0, 20)}...` : entrantDetails.name;

  const checkedIn = entrantDetails.checkedIn;
  const connectedToMatch = connectionState === 'CONNECTED';

  return (
    <React.Fragment>
      <EntrantSummaryModal entrantDetails={entrantDetails} open={expanded} handleClose={() => setExpanded(false)} />
      <Box
        display="flex"
        justifyContent='center'
        alignItems="center"
        gap={1}
        overflow="hidden"
        sx={{ borderRadius: 1 }}
      >
        <img
          style={{ height: 30, width: 30, borderRadius: 50, borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
          src={entrantDetails.avatarUrl || defaultProfilePic}
          alt={entrantDetails.name}
        />
        <Tooltip title='Player Seed'>
          <Typography color='#cfcfcf' fontSize={12} textAlign='center'>
            {entrantDetails.seed}
          </Typography>
        </Tooltip>
        <Link color='inherit' sx={{ cursor: 'pointer' }} onClick={() => setExpanded(true)}>
          <Tooltip title={entrantDetails.name}>
            <Typography textAlign='center' sx={{ wordBreak: 'break-word'}}>
              { truncatedName }
            </Typography>
          </Tooltip>
        </Link>
        <Tooltip title={checkedIn ? 'Checked In' : 'Not Checked In'}>
          <HowToReg color={checkedIn ? 'success' : 'disabled'} fontSize="small" />
        </Tooltip>
        {connectionState && (
          <Tooltip title={connectedToMatch ? 'Connected to match' : 'Not yet connected'}>
            <Cable color={connectedToMatch ? 'warning' : 'disabled'} fontSize="small" />
          </Tooltip>
        )}
      </Box>
    </React.Fragment>
  );
};

export default CardEntrantRow;