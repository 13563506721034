import battlefield from '../../assets/stages/ssbu/bf.png';
import small_battlefield from '../../assets/stages/ssbu/small_bf.jpeg';
import final_destination from '../../assets/stages/ssbu/fd.jpg';
import smashville from '../../assets/stages/ssbu/sv.png';
import pokemonstadium2 from '../../assets/stages/ssbu/ps2.png';
import town_and_city from '../../assets/stages/ssbu/tnc.png';
import kalos from '../../assets/stages/ssbu/kalos.png';
import yoshis_story from '../../assets/stages/ssbu/yoshisstory.png';
import lylat from '../../assets/stages/ssbu/lylat.jpg';
import northern_cave from '../../assets/stages/ssbu/nc.png';
import fountain from '../../assets/stages/ssbu/fountain.png';
import pokemonstadium from '../../assets/stages/ssbu/ps.png';
import yoshisisland from '../../assets/stages/ssbu/yoshisisland.png';
import warioware from '../../assets/stages/ssbu/warioware.png';
import castle_siege from '../../assets/stages/ssbu/castlesiege.png';
import duckhunt from '../../assets/stages/ssbu/duckhunt.png';
import hollowbastion from '../../assets/stages/ssbu/hollowbastion.jpeg';
import random from '../../assets/char_icons/ssbu/random.png';

export interface StageData {
    id: number
    name: string
    image: string
    abbreviatedName?: string
}

export type SSBUStage =
    'BATTLEFIELD' | 'SMALL_BATTLEFIELD' | 'FINAL_DESTINATION' | 'SMASHVILLE' |
    'POKEMON_STADIUM_2' | 'TOWN_AND_CITY' | 'KALOS_POKEMON_LEAGUE' | 'YOSHIS_STORY' |
    'LYLAT_CRUISE' | 'NORTHERN_CAVE' | 'FOUNTAIN_OF_DREAMS' | 'POKEMON_STADIUM' |
    'YOSHIS_ISLAND' | 'WARIOWARE_INC' | 'CASTLE_SIEGE' | 'DUCK_HUNT' | 'HOLLOW_BASTION' | 'DEFAULT'

export const allStages:Array<SSBUStage> = [
  'BATTLEFIELD', 'SMALL_BATTLEFIELD', 'FINAL_DESTINATION', 'SMASHVILLE',
  'POKEMON_STADIUM_2', 'TOWN_AND_CITY', 'KALOS_POKEMON_LEAGUE', 'HOLLOW_BASTION',
  'NORTHERN_CAVE', 'FOUNTAIN_OF_DREAMS', 'YOSHIS_STORY', 'LYLAT_CRUISE', 'YOSHIS_ISLAND',
  'POKEMON_STADIUM', 'CASTLE_SIEGE', 'WARIOWARE_INC'
];

export const ssbuStages: Record<SSBUStage, StageData> = {
  'BATTLEFIELD': {id: 1, name: 'Battlefield', image: battlefield},
  'SMALL_BATTLEFIELD': {id: 2, name: 'Small Battlefield', image: small_battlefield, abbreviatedName: 'Small BF'},
  'FINAL_DESTINATION': {id: 3, name: 'Final Destination', image: final_destination, abbreviatedName: 'FD'},
  'SMASHVILLE': {id: 4, name: 'Smashville', image: smashville},
  'POKEMON_STADIUM_2': {id: 5, name: 'Pokemon Stadium 2', image: pokemonstadium2, abbreviatedName: 'PS2'},
  'TOWN_AND_CITY': {id: 6, name: 'Town & City', image: town_and_city, abbreviatedName: 'T&C'},
  'KALOS_POKEMON_LEAGUE': {id: 7, name: 'Kalos Pokemon League', image: kalos, abbreviatedName: 'Kalos'},
  'YOSHIS_STORY': {id: 8, name: 'Yoshi\'s Story', image: yoshis_story, abbreviatedName: 'Y. Story'},
  'LYLAT_CRUISE': {id: 9, name: 'Lylat Cruise', image: lylat, abbreviatedName: 'Lylat'},
  'NORTHERN_CAVE': {id: 10, name: 'Northern Cave', image: northern_cave, abbreviatedName: 'N. Cave'},
  'FOUNTAIN_OF_DREAMS': {id: 11, name: 'Fountain of Dreams', image: fountain, abbreviatedName: 'FoD'},
  'POKEMON_STADIUM': {id: 12, name: 'Pokemon Stadium 1', image: pokemonstadium, abbreviatedName: 'PS1'},
  'YOSHIS_ISLAND': {id: 13, name: 'Yoshi\'s Island (Brawl)', image: yoshisisland, abbreviatedName: 'YI (Brawl)'},
  'WARIOWARE_INC': {id: 14, name: 'WarioWare Inc', image: warioware, abbreviatedName: 'WW'},
  'CASTLE_SIEGE': {id: 15, name: 'Castle Siege', image: castle_siege, abbreviatedName: 'C. Siege'},
  'DUCK_HUNT': {id: 16, name: 'Duck Hunt', image: duckhunt},
  'HOLLOW_BASTION': {id: 17, name: 'Hollow Bastion', image: hollowbastion, abbreviatedName: 'Hollow B.'},
  'DEFAULT': {id: 17, name: 'Default', image: random}
};

export const ssbuStageImages = [
  battlefield, small_battlefield, final_destination, smashville, pokemonstadium2, town_and_city,
  kalos, yoshis_story, lylat, northern_cave, fountain, pokemonstadium, yoshisisland, warioware,
  castle_siege, duckhunt, hollowbastion, random
];
