import React, {useContext, useEffect, useState} from 'react';
import tcsApi from '../api/tc-server/api';
import {AuthContext} from './AuthContext';

interface ExternalAccountContextInterface {
    providers: Array<string>,
    refreshProviders:() => Promise<void>
}

const ExternalAccountContext = React.createContext<ExternalAccountContextInterface>({} as ExternalAccountContextInterface);

interface Props {
    children?: React.ReactNode;
}

const ExternalAccountProvider = ({children}:Props):JSX.Element => {

  const { loggedIn } = useContext(AuthContext);
  const [externalAccounts, setExternalAccounts] = useState<Array<string>>();

  const fetchProviders = async () => setExternalAccounts(await tcsApi.auth.getProviders());

  useEffect(() => {
    if (loggedIn) {
      fetchProviders();
    } else {
      setExternalAccounts([]);
    }
  }, [loggedIn]);

  const providerValue:ExternalAccountContextInterface = {
    providers: externalAccounts ?? [],
    refreshProviders: fetchProviders
  };


  return <ExternalAccountContext.Provider value={providerValue}>
    {children}
  </ExternalAccountContext.Provider>;
};

export {ExternalAccountContext, ExternalAccountProvider};