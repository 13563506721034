import React, { useState, MouseEvent, ChangeEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { allCharacters, SSBUCharacter, ssbuCharacters } from '../types/ssbu/ssbu_characters'; // Adjust the import path as necessary

interface Props {
  selectedCharacter?: SSBUCharacter;
  onCharacterSelect: (character?: SSBUCharacter) => void;
}

const CharacterSelector = (props: Props) => {
  const { selectedCharacter, onCharacterSelect } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).closest('.clear-button')) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCharacterSelect = (character: SSBUCharacter) => {
    onCharacterSelect(character);
    handleClose();
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSelection = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onCharacterSelect(undefined);
  };

  const filteredCharacters = allCharacters.filter((character) =>
    ssbuCharacters[character].name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <IconButton onClick={handleClick}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectedCharacter ? (
            <>
              <img src={ssbuCharacters[selectedCharacter].icon} alt={`${selectedCharacter} Icon`} style={{ width: 40, height: 40 }} />
            </>
          ) : (
            <>
              <div style={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <PersonIcon />
              </div>
            </>
          )}
          <ArrowDropDownIcon />
          {selectedCharacter
            ? <IconButton size="small" onClick={handleClearSelection} className="clear-button"
              style={{padding: 0, marginLeft: 4}}>
              <CloseIcon fontSize="small"/>
            </IconButton>
            : <div style={{width: 24, height: 24, marginLeft: 4}}/>}
        </div>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <div style={{ padding: '8px', width: '1000px', height: '500px', overflowY: 'auto' }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search characters"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ marginBottom: '8px' }}
          />
          <Grid container spacing={2}>
            {filteredCharacters.map((character) => (
              <Grid item key={character}>
                <IconButton onClick={() => handleCharacterSelect(character)} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 80 }}>
                  <img src={ssbuCharacters[character].icon} alt={`${character} Icon`} style={{ width: 40, height: 40 }} />
                  <Typography variant="caption" align="center">{ssbuCharacters[character].name}</Typography>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </div>
      </Menu>
    </div>
  );
};

export default CharacterSelector;