import React from 'react';
import {Checkbox, FormControlLabel, Grid, Paper, Typography} from '@mui/material';
import {GroupConfigSet} from './InProgressTournament';

interface Props {
    amountMatches: number,
    checked: boolean,
    toggle: () => void,
    groupConfigs: GroupConfigSet
}

const MatchGroupHeaderCard = (props: Props): JSX.Element => {
  const { amountMatches, checked, groupConfigs, toggle } = props;
  const { headingName, borderColor, checkBoxColor } = groupConfigs;

  return <Grid item xs={3}>
    <Paper onClick={toggle} sx={{border: 1, borderColor: {borderColor}, cursor: 'pointer', padding: 1}}>
      <Typography fontWeight='bold' textAlign='center'>{headingName} ({amountMatches})</Typography>
      <FormControlLabel
        control={<Checkbox
          color={checkBoxColor}
          checked={checked}
        />}
        labelPlacement='start'
        label="Show?"
      />
    </Paper>
  </Grid>;
};

export default MatchGroupHeaderCard;
