import React, { useCallback, useEffect, useState } from 'react';
import tcsApi from '../api/tc-server/api';
import { EntrantDetailsDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';

interface TournamentEntrantsContextInterface {
  loading: boolean;
  entrantDetailsMap: Map<number, EntrantDetailsDTO>;
  setEntrantCheckedInStatus: (entrantId: number, checkedIn: boolean) => Promise<void>;

}

const TournamentEntrantsContext = React.createContext<TournamentEntrantsContextInterface>({} as TournamentEntrantsContextInterface);

interface Props {
  children?: React.ReactNode;
  tournamentId: number;
}

const TournamentEntrantsProvider = ({ children, tournamentId }:Props):JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [entrantDetailsMap, setEntrantDetailsMap] = useState(new Map<number, EntrantDetailsDTO>());


  useEffect(() => {
    const effect = async () => {
      const entrantDetails = await tcsApi.entrants.getForTournament(tournamentId);
      const map = new Map<number, EntrantDetailsDTO>();
      entrantDetails.detailList.forEach((detail) => map.set(detail.id, detail));
      setEntrantDetailsMap(map);
      setLoading(false);
    };

    void effect();
  }, [tournamentId]);

  const setEntrantCheckedInStatus = useCallback(async (entrantId: number, checkedIn: boolean): Promise<void> => {
    setEntrantDetailsMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(entrantId, { ...prevMap.get(entrantId)!, checkedIn});
      return newMap;
    });
  }, [setEntrantDetailsMap]);



  const providerValue:TournamentEntrantsContextInterface = {
    loading,
    entrantDetailsMap,
    setEntrantCheckedInStatus
  };


  return <TournamentEntrantsContext.Provider value={providerValue}>
    {children}
  </TournamentEntrantsContext.Provider>;
};

export {TournamentEntrantsContext, TournamentEntrantsProvider};