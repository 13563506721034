import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Button, Grid,
  LinearProgress,
} from '@mui/material';
import {StationGroupDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import TemplateApplicationManager, {Template} from './TemplateApplicationManager';
import {ManagedTournamentsContext} from '../context/ManagedTournamentsContext';
import {StationGroupTemplateContext} from '../context/StationTemplateContext';
import StationGroup from './StationGroup';
import tcsApi from '../api/tc-server/api';
import { useSnackAlert } from '../context/SnackAlertContext';

interface Props {
    tournamentId: number,
    storedStationGroup?: StationGroupDTO,
    updateStoredStationGroup: (val:StationGroupDTO) => void
}

const ManageStations = (props:Props):JSX.Element => {
  const { tournamentId, storedStationGroup, updateStoredStationGroup } = props;

  const { refreshTournaments } = useContext(ManagedTournamentsContext);
  const { templates } = useContext(StationGroupTemplateContext);

  const [stationNames, setStationNames] = useState<Array<string>>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<StationGroupDTO>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const openSnackAlert = useSnackAlert();

  const resetToStored = useCallback(() => {
    if (storedStationGroup){
      setStationNames(storedStationGroup.stationNames);
    } else {
      setStationNames(['']);
    }
  }, [storedStationGroup]);

  const applyTemplate = () => {
    if (selectedTemplate) {
      setStationNames(selectedTemplate.stationNames);
    }
  };

  const saveChanges = async () => {
    setSaveInProgress(true);
    const stationGroup:StationGroupDTO = {
      id: storedStationGroup?.id,
      stationNames,
      isTemplate: false,
      tournamentId
    };

    try {
      const saved = await tcsApi.stations.save(stationGroup);
      updateStoredStationGroup(saved);
      setSelectedTemplate(undefined);
      await refreshTournaments();
      openSnackAlert({
        message: { text: 'Station group updated!'},
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating station group', err);
      openSnackAlert({
        message: { text: 'Error updating station group' },
        severity: 'error'
      });
    } finally {
      setSaveInProgress(false);
    }
  };

  useEffect(() => {
    resetToStored();
  }, [resetToStored]);

  const updateSelectedTemplate = (val:Template | undefined) => setSelectedTemplate(val as StationGroupDTO);

  const handleUpdateStations = (stations:Array<string>) => setStationNames(stations);

  return <React.Fragment>
    <TemplateApplicationManager
      availableTemplates={templates as Array<Template>}
      handleApplyTemplate={applyTemplate}
      handleUpdateSelectedTemplate={updateSelectedTemplate}
      selectedTemplate={selectedTemplate as Template}
    />
    <Button onClick={() => setStationNames([])} sx={{ margin: 1}}>Clear Stations</Button>
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <Button disabled={saveInProgress} onClick={saveChanges}>Save</Button>
      </Grid>
    </Grid>
    {saveInProgress && <LinearProgress sx={{ marginX: 2}}/>}
    <StationGroup
      handleUpdateStations={handleUpdateStations}
      stations={stationNames}
    />

  </React.Fragment>;

  /*return <Accordion sx={{ marginBottom: 2 }} expanded={expanded} onChange={(event, expanded) => setExpanded(expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                    <Typography style={{ flex: 1}}><b>Manage Stations</b></Typography>
                    <span style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                        <Typography sx={{ color: 'text.secondary' }}><b>Status:</b> { storedStationGroup ? 'Complete' : 'Not Set'}</Typography>
                        { storedStationGroup && <CheckCircleIcon sx={{ marginLeft: 1}} color='success'/>}
                    </span>
                </span>
        </AccordionSummary>
        <AccordionDetails>
            <TemplateApplicationManager
                availableTemplates={templates as Array<Template>}
                handleApplyTemplate={applyTemplate}
                handleUpdateSelectedTemplate={updateSelectedTemplate}
                selectedTemplate={selectedTemplate as Template}
            />
            <Button onClick={() => setStationNames([])} sx={{ margin: 1}}>Clear Stations</Button>
            <StationGroup
                handleUpdateStations={handleUpdateStations}
                stations={stationNames}
            />
        </AccordionDetails>
        {saveInProgress && <LinearProgress sx={{ marginX: 4}}/>}
        <AccordionActions>
            <Button disabled={saveInProgress} onClick={onCancel}>Cancel</Button>
            <Button disabled={saveInProgress} onClick={saveChanges}>Save</Button>
        </AccordionActions>
    </Accordion>*/
};

export default ManageStations;
