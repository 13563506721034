import React, { useContext, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from '@mui/material';
import tcsApi from '../api/tc-server/api';
import { TournamentEntrantsContext } from '../context/TournamentEntrantContext';
import { useSnackAlert } from '../context/SnackAlertContext';

interface Props {
    handleClose: () => void
    open: boolean,
    entrantId: number,
}

const ConfirmUnlinkModal = (props:Props):JSX.Element => {
  const [unlinkInProgress, setUnlinkInProgress] = useState(false);
  const [error, setError] = useState(false);

  const { setEntrantCheckedInStatus } = useContext(TournamentEntrantsContext);
  const openSnackAlert = useSnackAlert();
  const { handleClose, open, entrantId } = props;

  const closeModal = () => {
    handleClose();
    setError(false);
  };

  const unlinkUser = async () => {
    try {
      setError(false);
      setUnlinkInProgress(true);
      await tcsApi.entrants.checkEntrantOut(entrantId);
      void setEntrantCheckedInStatus(entrantId, false);
      openSnackAlert({
        message: { text: 'User successfully unlinked from entrant!' },
        severity: 'success'
      });
      setError(false);
      closeModal();
    } catch (err) {
      setError(true);
      console.error('failed to reset bracket', err);
    }
    setUnlinkInProgress(false);
  };

  return <Dialog maxWidth={'sm'} open={open} onClose={unlinkInProgress ? undefined : closeModal}>
    <DialogTitle>Unlink User from this Entrant?</DialogTitle>
    {error && <Alert variant='filled' severity="error">Failed to Unlink User.</Alert>}
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
                This will cause the entrant to be checked out of the tournament, and will unlink the Tourney Companion user from this entrant.
                This entrant will once again be available for check-in.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button disabled={unlinkInProgress} onClick={closeModal}>Cancel</Button>
      <Button disabled={unlinkInProgress} onClick={unlinkUser}>Unlink User</Button>
    </DialogActions>
    {unlinkInProgress && <LinearProgress />}
  </Dialog>;
};

export default ConfirmUnlinkModal;
