import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Grid, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MatchDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import PeopleIcon from '@mui/icons-material/People';

interface Props {
  noDivider?: boolean,
  round: number,
  roundName: string,
  isBo5: boolean,
  matchCards: Array<JSX.Element>
  defaultClosed?: boolean
}

interface Group {
  round: number;
  roundName: string;
  matches: Array<MatchDTO>;
  isBo5: boolean;
}

function compareGroup(a: Group, b: Group): number {
  if (a.round < 0 && b.round > 0) {
    return -1;
  } else if (a.round > 0 && b.round < 0) {
    return 1;
  }

  return Math.abs(a.round) > Math.abs(b.round) ? 1 : -1;
}

export const groupMatchesByRound = (matches: Array<MatchDTO>): Array<Group> => {
  const groups: Array<Group> = [];
  matches.forEach(match => {
    const { round } = match;
    const existingGroup = groups.find(group => group.round === round);
    if (!existingGroup) {
      const newGroup: Group = {
        round,
        roundName: match.roundText,
        matches: [match],
        isBo5: match.isBo5
      };
      groups.push(newGroup);
    } else {
      existingGroup.matches.push(match);
    }
  });

  return groups.sort(compareGroup);
};

const MatchGroup = (props: Props): JSX.Element => {
  const {
    defaultClosed = false,
    noDivider = false,
    round,
    roundName,
    isBo5,
    matchCards
  } = props;
  return (
    <React.Fragment>
      <Accordion defaultExpanded={!defaultClosed} sx={{ marginX: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${round}-content`}
          id={`panel${round}-header`}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Chip sx={{ backgroundColor: '#3e5c69' }} label={roundName} />
            <Chip color={isBo5 ? 'warning' : 'success'} label={isBo5 ? 'Best of 5' : 'Best of 3'} />
            <Chip icon={<PeopleIcon/>} sx={{ paddingLeft: 1}} label={`Matches: ${matchCards.length}`} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row" spacing={2}>
            {
              matchCards.map((card, index) => (
                <Grid key={index} item>
                  {card}
                </Grid>
              ))
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
      {!noDivider && <Divider sx={{ marginY: 2 }} />}
    </React.Fragment>
  );
};

export default MatchGroup;