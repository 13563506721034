import React, {ReactElement, FC, useState, useContext} from 'react';
import {Container, Grid, Typography} from '@mui/material';
import TournamentListing from '../components/TournamentListing';
import AddTournamentModal from '../modals/AddTournamentModal';
import AddTournamentCard from '../components/AddTournamentCard';
import {ManagedTournamentsContext} from '../context/ManagedTournamentsContext';

const Tournaments: FC = (): ReactElement => {
  const [addTournamentModalOpen, setAddTournamentModalOpen] = useState(false);
  const { tournaments } = useContext(ManagedTournamentsContext);

  return (
    <React.Fragment>
      <AddTournamentModal open={addTournamentModalOpen} handleClose={() => setAddTournamentModalOpen(false)}/>
      <Container>
        <Typography style={{ marginTop: 20 }} variant="h4">Current and Upcoming Tournaments</Typography>
        <Grid
          container
          spacing={2}
          marginBottom={6}
        >
          <Grid item md={3}><AddTournamentCard handleClick={() => setAddTournamentModalOpen(true)}/></Grid>
          { (tournaments || []).filter(tournament => tournament.status !== 'COMPLETE').map(tournament => <TournamentListing key={tournament.id} tournament={tournament}/>)}
        </Grid>
        <Typography style={{ marginTop: 20 }} variant="h4">Completed Tournaments</Typography>
        <Grid
          container
          spacing={2}
          marginBottom={6}
        >
          { (tournaments || []).filter(tournament => tournament.status === 'COMPLETE').map(tournament => <TournamentListing key={tournament.id} tournament={tournament}/>)}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Tournaments;