import { Box, Button, Container, Grid, LinearProgress } from '@mui/material';
import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import logo from '../assets/tc_logo_no_bg.png';
import startggLogo from '../assets/startgg_logo.png';
import getProvider from '../api/auth/oauth/providers';

interface NavState {
    path: string
}

const Login = ():JSX.Element => {
  const { initialized, loggedIn } = useContext(AuthContext);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const state = useLocation().state as NavState;
  const navigate = useNavigate();

  const postLoginNavigate = useCallback(() => navigate(state?.path || '/', { replace: true }), [navigate, state]);
  const startggLogin = async () => {
    setLoginInProgress(true);
    await getProvider('startgg').initiateOauthFlow();
  };

  useEffect(() => {
    if(loggedIn){
      postLoginNavigate();
    }
  }, [loggedIn, postLoginNavigate]);

  if (!initialized || loggedIn) {
    return <></>;
  }

  return (
    <div>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          style={{ minHeight: '75vh' }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <img style={{ width: 400}} src={logo} alt='Tourney Companion'/>
          </Box>
          <Button
            disabled={loginInProgress}
            onClick={startggLogin}
            variant="contained"
            style={{ padding: 8, background: '#332F32', color: 'white'}}
            startIcon={<img style={{ marginRight: 20, height: 30, width: 30}} src={startggLogo} alt='Startgg'/>}>
                            Login with Startgg
          </Button>
          {loginInProgress && <LinearProgress/>}
        </Grid>
      </Container>
    </div>
  );
};

export default Login;