import React, {useContext} from 'react';
import {AuthContext, AuthProvider} from './AuthContext';
import {ExternalAccountProvider} from './ExternalAccountContext';
import {ManagedTournamentsContext, ManagedTournamentsProvider} from './ManagedTournamentsContext';
import {CircularProgress} from '@mui/material';
import {RulesetTemplateContext, RulesetTemplateProvider} from './RulesetTemplateContext';
import {StationGroupTemplateProvider} from './StationTemplateContext';
import { SnackAlertProvider } from './SnackAlertContext';

interface Props {
    children?: React.ReactNode
}

const ContextWrapper = ({children}: Props): JSX.Element => {
  return <SnackAlertProvider>
    <AuthProvider>
      <ExternalAccountProvider>
        <ManagedTournamentsProvider>
          <RulesetTemplateProvider>
            <StationGroupTemplateProvider>
              <ContextLoader>
                {children}
              </ContextLoader>
            </StationGroupTemplateProvider>
          </RulesetTemplateProvider>
        </ManagedTournamentsProvider>
      </ExternalAccountProvider>
    </AuthProvider>
  </SnackAlertProvider>;
};

const ContextLoader = ({children}: Props): JSX.Element => {
  const {loggedIn} = useContext(AuthContext);
  const {loading: tournamentsLoading} = useContext(ManagedTournamentsContext);
  const {loading: templatesLoading} = useContext(RulesetTemplateContext);

  const loading = (tournamentsLoading || templatesLoading);

  if (loading && loggedIn) {
    return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress/>
    </div>;
  } else {
    return <>{children}</>;
  }

};

export default ContextWrapper;
