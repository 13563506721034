import React from 'react';
import {Button, Card, CardActions, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {TournamentDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {useNavigate} from 'react-router-dom';
import tcLogo from '../assets/tc_logo_no_text_margins.png';

interface Props {
    tournament: TournamentDTO
}

const TournamentListing = (props: Props): JSX.Element => {
  const { tournament } = props;
  const navigate = useNavigate();

  const navigateToTournament = () => navigate(`/tournaments/${tournament.id}`);
    
  return <Grid item md={3}>
    <Card onClick={navigateToTournament} raised sx={{ cursor: 'pointer', marginTop: 3, width: 200, height: 350}}>
      <CardMedia
        component="img"
        height={200}
        width={200}
        image={tournament.imageUrl || tcLogo}
        alt={tournament.name}
        sx={{objectFit: 'contain', backgroundColor: '#262626'}}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {tournament.name}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={navigateToTournament} size="small">View / Manage</Button>
      </CardActions>
    </Card>
  </Grid>;
};

export default TournamentListing;
