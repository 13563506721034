import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  children?: React.ReactNode
}

const BetaGate = ({ children }: Props): JSX.Element => {
  const { betaAvailability: { enrolled }, initialized, loggedIn, logout } = useContext(AuthContext);
  if (!initialized) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress/>
      </div>
    );
  }
  if (loggedIn && !enrolled) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        marginLeft: '250px', // Add left margin
        marginRight: '250px' // Add right margin
      }}>
        <h1>Sorry! Only members of the beta program can run tournaments on Tourney Companion at this time.</h1>
        <a href="https://tourneycompanion.com/" target="_blank" rel="noopener noreferrer">
          Click here to apply for our Summer Beta program!
        </a>
        <Button sx={{ marginTop: 4 }}onClick={logout}>Log Out</Button>
      </div>
    );
  }
  return <>{children}</>;
};

export default BetaGate;