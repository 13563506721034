import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MatchDTO, RulesetDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {
  Card, Chip,
  Divider,
  FormControl,
  Grid, IconButton,
  InputLabel, Menu,
  MenuItem, Paper,
  Select, Tooltip,
  Typography
} from '@mui/material';
import tcsApi from '../api/tc-server/api';
import {MatchStateResponse} from './InProgressTournament';
import { TournamentEntrantsContext } from '../context/TournamentEntrantContext';
import CardEntrantRow from './CardEntrantRow';
import { MoreVert, Schedule } from '@mui/icons-material';
import ConfirmResetMatchModal from '../modals/ConfirmResetMatchModal';
import SetScoreModal from '../modals/SetScoreModal';
import ReportDisqualificationModal from '../modals/ReportDisqualificationModal';
import useTimeSince from '../hooks/useTimeSince';

type MenuOption = 'reset' | 'report' | 'dq';

interface Props {
    entrantIds: Array<number>;
    match: MatchDTO;
    matchState: MatchStateResponse;
    stations: Array<string>;
    stationsInUse: Array<string>;
    updateMatch: (val: MatchDTO) => void;
    handleRefreshCompletedMatches: () => Promise<void>;
    handleMatchComplete: (matchId: number) => Promise<void>;
    ruleset: RulesetDTO;
}

const matchStateToDescription = (matchState: MatchStateResponse): string => {
  switch (matchState.matchStatus) {
  case 'DOUBLE_BLIND':
    if (matchState.syncedEntrants.length < 2) {
      return 'Waiting for Players to Sync';
    }
    return 'In Character Select';
  case 'STAGE_STRIKING':
    return 'Stage Striking';
  case 'PENDING_REPORT':
    return 'Game in Progress (Awaiting Report)';
  case 'STAGE_BAN':
    return 'Banning Stages';
  case 'COUNTER_STAGE':
    return 'Selecting Counterpick';
  case 'BLIND_CHARACTER':
    return 'Selecting Blind Character';
  case 'COUNTER_CHARACTER':
    return 'Selecting Counter Character';
  case 'COMPLETE':
    return 'Complete!';
  }
};

const InProgressMatchCard = (props: Props): JSX.Element => {
  const {entrantIds, match, matchState, stations, stationsInUse, updateMatch, handleRefreshCompletedMatches, handleMatchComplete, ruleset} = props;
  const { entrantDetailsMap } = useContext(TournamentEntrantsContext);
  const [selectedStation, setSelectedStation] = useState<string>('');
  const [resetSetModalOpen, setResetSetModalOpen] = useState(false);
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  const [dqModalOpen, setDqModalOpen] = useState(false);
  const timeSinceMatchStarted = useTimeSince(new Date(matchState.createdAt));
  const timeSinceLastStateChange = useTimeSince(new Date(matchState.lastStateChangeAt));

  // Start Menu Control
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
    // End Menu Control

  const handleSelectMenuOption = (option: MenuOption) => {
    switch (option) {
    case 'reset':
      setResetSetModalOpen(true);
      break;
    case 'report':
      setScoreModalOpen(true);
      break;
    case 'dq':
      setDqModalOpen(true);
      break;
    }
    handleMenuClose();
  };

  useEffect(() => {
    if (stations.length > 0) {
      setSelectedStation(match.assignedStation ?? '');
    }
  }, [match.assignedStation, stations]);

  const assignStation = async (station: string) => {
    setSelectedStation(station);
    const updatedMatch = await tcsApi.matches.assignStation({station, matchId: match.id});
    updateMatch(updatedMatch);
  };

  const menuId = `match-${match.id}-menu`;

  const stationSelectable = (station: string) => !stationsInUse.filter(item => item !== selectedStation).includes(station);

  const entrant1Details = useMemo(() => entrantDetailsMap.get(entrantIds[0])!, [entrantDetailsMap, entrantIds]);
  const entrant2Details = useMemo(() => entrantDetailsMap.get(entrantIds[1])!, [entrantDetailsMap, entrantIds]);

  const p1Score = matchState.score.find(score => score.entrantId === entrant1Details.id)?.score ?? 0;
  const p2Score = matchState.score.find(score => score.entrantId === entrant2Details.id)?.score ?? 0;

  return <Card variant='outlined' sx={{ backgroundColor: '#1E1E1E', minWidth: 250, maxWidth: 300, paddingX: 1, paddingBottom: 1 }}>
    <SetScoreModal
      handleClose={() => setScoreModalOpen(false)}
      open={scoreModalOpen}
      matchId={match.id}
      entrants={[entrant1Details, entrant2Details]}
      isBo5={match.isBo5}
      handleMatchComplete={handleMatchComplete}
      ruleset={ruleset}
    />
    <ReportDisqualificationModal
      handleClose={() => setDqModalOpen(false)}
      open={dqModalOpen}
      matchId={match.id}
      entrants={[entrant1Details, entrant2Details]}
      handleMatchComplete={handleMatchComplete}
    />
    <ConfirmResetMatchModal
      handleClose={() => setResetSetModalOpen(false)}
      open={resetSetModalOpen}
      matchId={match.id}
      isComplete={false}
      handleRefreshCompletedMatches={handleRefreshCompletedMatches}
    />
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      paddingLeft={1}
      paddingBottom={1}
    >
      <Grid item>
        <Tooltip title='Time since match called'>
          <Grid container direction='row' alignItems='center' gap={1}>
            <Schedule/>
            <Typography>{timeSinceMatchStarted}</Typography>
          </Grid>
        </Tooltip>
      </Grid>
      <IconButton
        aria-controls={menuOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuClick}
      >
        <MoreVert/>
      </IconButton>
    </Grid>
    <Menu
      id="basic-menu"
      anchorEl={menuAnchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleSelectMenuOption('report')}>Manually Enter Score</MenuItem>
      <MenuItem onClick={() => handleSelectMenuOption('reset')}>Reset Match</MenuItem>
      <MenuItem onClick={() => handleSelectMenuOption('dq')}>Report DQ</MenuItem>
    </Menu>
    <Grid container direction='row' justifyContent='space-between'>
      <Grid item xs={9} sx={{ marginLeft: 1 }}>
        <CardEntrantRow
          entrantDetails={entrant1Details}
          connectionState={matchState.syncedEntrants.includes(entrant1Details.id) ? 'CONNECTED' : 'DISCONNECTED'}
        />
      </Grid>
      <Paper elevation={8} sx={{
        backgroundColor: '#005db0',
        width: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 1,
      }}><Typography variant='h5' textAlign='center'><b>{p1Score}</b></Typography></Paper>
    </Grid>
    <Divider>vs</Divider>
    <Grid container direction='row' justifyContent='space-between' marginBottom={1}>
      <Grid item xs={9} sx={{ marginLeft: 1 }}>
        <CardEntrantRow
          entrantDetails={entrant2Details}
          connectionState={matchState.syncedEntrants.includes(entrant2Details.id) ? 'CONNECTED' : 'DISCONNECTED'}
        />
      </Grid>
      <Paper elevation={8} sx={{
        backgroundColor: '#005db0',
        width: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 1,
      }}><Typography variant='h5' textAlign='center'><b>{p2Score}</b></Typography></Paper>
    </Grid>
    <Paper sx={{padding: 1}}>
      <Grid container justifyContent='space-between' direction='row'>
        <Typography variant='subtitle2' color='gray'>Current Status:</Typography>
        <Tooltip title={'Time since last update'}>
          <Chip
            icon={<Schedule/>}
            label={timeSinceLastStateChange}
          />
        </Tooltip>
      </Grid>
      <Typography variant='subtitle1'>{matchStateToDescription(matchState)}</Typography>
    </Paper>
    <FormControl fullWidth sx={{marginTop: 3}}>
      <InputLabel>Station</InputLabel>
      <Select
        name="Station"
        label="Station"
        value={selectedStation}
        onChange={(event) => assignStation(`${event.target.value}`)}
      >
        {stations.map((item) => <MenuItem disabled={!stationSelectable(item)} key={item}
          value={item}>{item}</MenuItem>)}
      </Select>
    </FormControl>
  </Card>;
};

export default InProgressMatchCard;
