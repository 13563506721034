import React from 'react';
import {Add} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';

interface Props {
    handleClick: () => void
}

const AddTournamentCard = (props:Props):JSX.Element => {
  const { handleClick } = props;

  return <Button
    sx={{ marginTop: 3}}
    onClick={handleClick}
  >
    <Box
      sx={{ display: 'flex', flexDirection: 'column', color: 'primary.main', border: 2, width: 200, height:350, textDecoration: 'none' }}
      justifyContent='center'
      alignItems='center'
    >
      <Add sx={{ textAlign: 'center', font: 'inherit', fontSize: 75 }}/>
      <Typography>Add Tournament</Typography>
    </Box>
  </Button>;
};

export default AddTournamentCard;