import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Grid, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
  availableStations: string[];
  stationsInUse: string[];
  stationsReadyToCall: string[];
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StationAvailability = (props: Props) => {
  const { availableStations, stationsInUse, stationsReadyToCall } = props;

  return (
    <Paper
      component="ul"
      sx={{ borderRadius: 3, paddingTop: 1 }}
    >
      <Typography
        variant="body1"
      >
        Station Pool:
      </Typography>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 1,
          marginHorizontal: 0,
          marginVertical: .5
        }}>
        {availableStations.map((name, i) => {
          return (
            stationsReadyToCall.includes(name)
              ? <ListItem key={i}>
                <Tooltip title={`A match assigned to Station ${name} is ready to be called.`}>
                  <Chip
                    color='warning'
                    label={name}
                  />
                </Tooltip>
              </ListItem>
              : <ListItem key={i}>
                <Tooltip title={`Station ${name} is available and has no matches assigned to it.`}>
                  <Chip
                    label={name}
                  />
                </Tooltip>
              </ListItem>
          );
        })}
        {stationsInUse.map((name, i) => {
          return (
            <Tooltip key={i + availableStations.length} title={`Station ${name} is currently in-use.`}>
              <ListItem>
                <Chip
                  color='primary'
                  disabled
                  label={name}
                />
              </ListItem>
            </Tooltip>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default StationAvailability;