import React, {useContext, useState} from 'react';
import {Button, Checkbox, FormControlLabel, LinearProgress, Paper, Stack, Typography} from '@mui/material';
import tcsApi from '../api/tc-server/api';
import {ManagedTournamentsContext} from '../context/ManagedTournamentsContext';
import { isAxiosError } from 'axios';
import Alert from '@mui/material/Alert';

interface Props {
  tournamentId: number
}

const LaunchPane = (props:Props):JSX.Element => {
  const { tournamentId } = props;
  const [launchInProgress, setLaunchInProgress] = useState(false);
  const [errorString, setErrorString] = useState('');
  const { refreshTournaments } = useContext(ManagedTournamentsContext);

  // Initialize an array of boolean values for the checkboxes
  const [checked, setChecked] = useState([false, false, false]);

  const launchTournament = async () => {
    setErrorString('');
    setLaunchInProgress(true);
    try {
      await tcsApi.tournaments.launch(tournamentId);
      await refreshTournaments();
    } catch (err) {
      if (isAxiosError(err)) {
        const axiosErr = err as { response?: { data?: { message?: string } } };
        if (axiosErr?.response?.data?.message) {
          setErrorString(axiosErr.response.data.message);
        }
      }
    } finally {
      setLaunchInProgress(false);
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (index: number) => {
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
  };

  return <Paper sx={{ margin: 1, paddingY: 3, paddingX: 1}} elevation={6}>
    <Stack marginX={3} spacing={2}>
      <Typography textAlign='center' variant='h5'>Looks like your tournament is all configured!</Typography>
      <Typography>Ready to start your Tourney Companion tournament? Once launched, competitors will be able to view the tournament from their Tourney Companion app and check-in as attendees.</Typography>
      <Typography color='yellow'><b>Warning: Skipping over the below bullet points could result in a broken tournament experience. Read them carefully!</b></Typography>
      <Typography><b>Please confirm all of the following before launching the tournament: </b></Typography>
      <FormControlLabel control={<Checkbox checked={checked[0]} onChange={() => handleCheckboxChange(0)} />} label="Registration for this event is closed, and all sign-ups, seeding, and bracket organization are complete." />
      <FormControlLabel control={<Checkbox checked={checked[1]} onChange={() => handleCheckboxChange(1)} />} label="This event is a double-elimination bracket (no round robin, swiss, etc.)." />
      <FormControlLabel control={<Checkbox checked={checked[2]} onChange={() => handleCheckboxChange(2)} />} label="I acknowledge that all match reporting for this event should be handled via Tourney Companion and NOT using the Startgg interface. (If any sets have already been called/reported, please reset the bracket on Startgg prior to checking this box)." />
      <Typography color='gray'><i>Note: If any of the above is inaccurate, we cannot guarantee that Tourney Companion will handle your event properly!</i></Typography>
      {errorString && <Alert variant='filled' severity="error">{errorString}</Alert>}
      <Button onClick={launchTournament} variant='contained' disabled={launchInProgress || checked.includes(false)}>Launch</Button>
      { launchInProgress && <LinearProgress/> }
    </Stack>
  </Paper>;
};

export default LaunchPane;