import { useState, useEffect } from 'react';

const useTimeSince = (date: Date) => {
  const [timeDifference, setTimeDifference] = useState('00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const diff = Math.floor((now - date.getTime()) / 1000);
      const minutes = Math.floor(diff / 60);
      const seconds = diff % 60;
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
      setTimeDifference(`${formattedMinutes}:${formattedSeconds}`);
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return timeDifference;
};

export default useTimeSince;
