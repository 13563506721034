import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MatchDTO, RulesetDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {
  Button,
  Card, CircularProgress,
  Divider,
  FormControl,
  Grid, IconButton,
  InputLabel, ListSubheader, Menu,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import tcsApi from '../api/tc-server/api';
import { groupConfigs } from './InProgressTournament';
import { TournamentEntrantsContext } from '../context/TournamentEntrantContext';
import CardEntrantRow from './CardEntrantRow';
import SetScoreModal from '../modals/SetScoreModal';
import ReportDisqualificationModal from '../modals/ReportDisqualificationModal';
import { MoreVert } from '@mui/icons-material';

type MenuOption = 'report' | 'dq';

interface Props {
  entrantIds: Array<number>;
  match: MatchDTO;
  stations: Array<string>;
  stationsInUse: Array<string>;
  updateMatch: (val: MatchDTO) => void;
  handleMatchComplete: (matchId: number) => Promise<void>;
  ruleset: RulesetDTO;
}

const UncalledMatchCard = (props: Props): JSX.Element => {
  const {entrantIds, match, stations, stationsInUse, updateMatch, handleMatchComplete, ruleset } = props;
  const { entrantDetailsMap } = useContext(TournamentEntrantsContext);
  const [selectedStation, setSelectedStation] = useState<string>('');
  const [callInProgress, setCallInProgress] = useState(false);
  const [scoreModalOpen, setScoreModalOpen] = useState(false);
  const [dqModalOpen, setDqModalOpen] = useState(false);

  // Start Menu Control
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  // End Menu Control

  const handleSelectMenuOption = (option: MenuOption) => {
    switch (option) {
    case 'report':
      setScoreModalOpen(true);
      break;
    case 'dq':
      setDqModalOpen(true);
      break;
    }
    handleMenuClose();
  };

  const availableStations = useMemo(() => stations.filter((station) => !stationsInUse.includes(station)), [stationsInUse, stations]);

  useEffect(() => {
    if (stations.length > 0) {
      setSelectedStation(match.assignedStation ?? '');
    }
  }, [match.assignedStation, stations]);

  const assignStation = async (station: string) => {
    setSelectedStation(station);
    const updatedMatch = await tcsApi.matches.assignStation({station, matchId: match.id});
    updateMatch(updatedMatch);
  };

  const callMatch = async () => {
    setCallInProgress(true);
    try {
      const [updatedMatch] = await tcsApi.matches.call({ matchIds: [match.id] });
      updateMatch(updatedMatch);
    } catch (err) {
      console.error('failed to call match', err);
    }
    setCallInProgress(false);
  };

  const startEnabled = selectedStation !== '' &&
      !stationsInUse.includes(selectedStation) &&
      entrantIds.length === 2;

  const menuId = `match-${match.id}-menu`;

  const entrant1Details = useMemo(() => entrantDetailsMap.get(entrantIds[0]), [entrantDetailsMap, entrantIds]);
  const entrant2Details = useMemo(() => entrantDetailsMap.get(entrantIds[1]), [entrantDetailsMap, entrantIds]);

  return <React.Fragment>
    <Card variant='outlined' sx={{ backgroundColor: '#1E1E1E', width: 200, padding: 1}}>
      { Boolean(entrant1Details && entrant2Details) && <>
        <SetScoreModal
          handleClose={() => setScoreModalOpen(false)}
          open={scoreModalOpen}
          matchId={match.id}
          entrants={[entrant1Details!, entrant2Details!]}
          isBo5={match.isBo5}
          handleMatchComplete={handleMatchComplete}
          ruleset={ruleset}
        />
        <ReportDisqualificationModal
          handleClose={() => setDqModalOpen(false)}
          open={dqModalOpen}
          matchId={match.id}
          entrants={[entrant1Details!, entrant2Details!]}
          handleMatchComplete={handleMatchComplete}
        />
        <Grid container direction='column'>
          <IconButton
            sx={{ alignSelf: 'flex-end' }}
            aria-controls={menuOpen ? menuId : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={handleMenuClick}
          >
            <MoreVert/>
          </IconButton>
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleSelectMenuOption('report')}>Manually Enter Score</MenuItem>
          <MenuItem onClick={() => handleSelectMenuOption('dq')}>Report DQ</MenuItem>
        </Menu>
      </>}
      <CardEntrantRow entrantDetails={entrant1Details}/>
      <Divider>vs</Divider>
      <CardEntrantRow entrantDetails={entrant2Details}/>
      <FormControl color={groupConfigs.uncalledGroupConfigSet.checkBoxColor} size='small' fullWidth sx={{marginTop: 1}}>
        <InputLabel>Select Station</InputLabel>
        <Select
          name="Station"
          label="Station"
          value={selectedStation}
          onChange={(event) => assignStation(`${event.target.value}`)}
        >
          {availableStations.length > 0 && <ListSubheader>Available Stations</ListSubheader>}
          {availableStations.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          {stationsInUse.length > 0 && <ListSubheader>In-Use Stations</ListSubheader>}
          {stationsInUse.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
        </Select>
      </FormControl>
      <Grid container direction='row' justifyContent='space-evenly'>
        <Tooltip title='Call Match'><span>
          {
            callInProgress
              ? <CircularProgress
                sx={{ marginY: 1 }}
                size={20}
                color={groupConfigs.uncalledGroupConfigSet.checkBoxColor}
              />
              : <Button
                color={groupConfigs.uncalledGroupConfigSet.checkBoxColor}
                onClick={callMatch} disabled={!startEnabled}
                fullWidth>
                <PlayCircleFilledIcon/>
              </Button>
          }
        </span></Tooltip>
      </Grid>
    </Card>
  </React.Fragment>;
};

export default UncalledMatchCard;
