import React from 'react';
import { EntrantDetailsDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import defaultProfilePic from '../assets/default_profile_pic.jpeg';
import ConfirmUnlinkModal from '../modals/ConfirmUnlink.tsx';

interface Props {
  entrantDetails: EntrantDetailsDTO;
  open: boolean;
  handleClose: () => void;
}

const EntrantSummaryModal = (props: Props): JSX.Element => {
  const { entrantDetails, open, handleClose } = props;
  const [unlinkConfirmationOpen, setUnlinkConfirmationOpen] = React.useState(false);

  return <Dialog maxWidth={false} open={open} onClose={handleClose}>
    <ConfirmUnlinkModal
      handleClose={() => setUnlinkConfirmationOpen(false)}
      open={unlinkConfirmationOpen}
      entrantId={entrantDetails.id}
    />
    <Stack sx={{ marginX: 3, marginY: 1, alignItems: 'center' }} alignItems='space-between'>
      <Typography sx={{ marginBottom: 1 }} fontWeight='bold' textAlign='center'>{ entrantDetails.name }</Typography>
      <img style={{ height: 100, width: 100, borderRadius: 50, borderStyle: 'solid', borderWidth: 2, borderColor: 'white' }}
        src={entrantDetails.avatarUrl || defaultProfilePic}
        alt={entrantDetails.name}
      />
      { entrantDetails.checkedIn && <Button onClick={() => setUnlinkConfirmationOpen(true)}>Unlink User</Button> }
    </Stack>

  </Dialog>;
};

export default EntrantSummaryModal;