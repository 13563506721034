import React, { useContext, useEffect, useMemo, useState} from 'react';
import { ManagedTournamentsContext } from '../context/ManagedTournamentsContext';
import {RulesetDTO, StationGroupDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import tcsApi from '../api/tc-server/api';
import { useParams } from 'react-router-dom';
import PreLaunchTournament from '../components/PreLaunchTournament';
import {Container} from '@mui/material';
import TournamentHeader from '../components/TournamentHeader';
import InProgressTournament from '../components/InProgressTournament';
import { TournamentEntrantsProvider } from '../context/TournamentEntrantContext';
import CompletedTournament from '../components/CompletedTournament';

interface LoadingState {
    rulesetLoading: boolean,
    stationGroupLoading: boolean
}

const Tournament = ():JSX.Element => {
  const { id } = useParams();
  const { tournaments } = useContext(ManagedTournamentsContext);

  const [loading, setLoading] = useState<LoadingState>({ rulesetLoading: true, stationGroupLoading: true });
  const [storedRuleset, setStoredRuleset] = useState<RulesetDTO>();
  const [storedStationGroup, setStoredStationGroup] = useState<StationGroupDTO>();

  const tournament = useMemo(() => tournaments.find(item => `${item.id}` === id), [id, tournaments]);

  const updateStoredRuleset = (val:RulesetDTO) => setStoredRuleset(val);
  const updateStoredStationGroup = (val:StationGroupDTO) => setStoredStationGroup(val);

  if (!tournament) {
    throw new Error('Sorry! This tournament either doesn\'t exist or you don\'t have permission to view it.');
  }

  useEffect(() => {
    const effect = async () => {
      if (tournament.rulesetId) {
        const ruleset = await tcsApi.rulesets.getById(tournament.rulesetId);
        setStoredRuleset(ruleset);
      }
      setLoading(prevState => ({ ...prevState, rulesetLoading: false }));
    };
    effect();
  }, [tournament]);

  useEffect(() => {
    const effect = async () => {
      if (tournament.stationGroupId) {
        const stationGroup = await tcsApi.stations.getById(tournament.stationGroupId);
        const numericalEntries = stationGroup.stationNames.filter(str => !isNaN(Number(str)));
        const nonNumericalEntries = stationGroup.stationNames.filter(str => isNaN(Number(str)));
        stationGroup.stationNames = [
          ...numericalEntries.sort((a, b) => Number(a) - Number(b)),
          ...nonNumericalEntries.sort()
        ];
        setStoredStationGroup(stationGroup);
      }
      setLoading(prevState => ({ ...prevState, stationGroupLoading: false }));
    };
    effect();
  }, [tournament]);

  const renderContents = () => {
    switch(tournament.status){
    case 'NOT_STARTED':
      return <PreLaunchTournament
        tournament={tournament}
        storedRuleset={storedRuleset}
        storedStationGroup={storedStationGroup}
        loading={loading.rulesetLoading || loading.stationGroupLoading}
        handleUpdateStoredRuleset={updateStoredRuleset}
        handleUpdateStoredStationGroup={updateStoredStationGroup}
      />;
    case 'IN_PROGRESS':
      return <TournamentEntrantsProvider tournamentId={tournament.id!}>
        <InProgressTournament
          tournamentId={tournament.id!}
          storedRuleset={storedRuleset}
          updateStoredRuleset={updateStoredRuleset}
          storedStationGroup={storedStationGroup}
          updateStoredStationGroup={updateStoredStationGroup}
          loading={loading.rulesetLoading || loading.stationGroupLoading}
        />
      </TournamentEntrantsProvider>;
    case 'COMPLETE':
      return <TournamentEntrantsProvider tournamentId={tournament.id!}>
        <CompletedTournament tournamentId={tournament.id!} />
      </TournamentEntrantsProvider>;
    }
  };

  return <Container>
    <TournamentHeader tournament={tournament}/>
    { renderContents() }
  </Container>;
};

export default Tournament;
