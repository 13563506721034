import { auth } from '../auth/firebase';
import { sdkGenerator } from '@tourneycompanion/tcs-js-sdk';
import { env } from '../../config/env';

const sdk = sdkGenerator({
  baseUrl: env.api.tcsUrl,
  firebaseAuth: auth,
});

export default sdk.operations;

export const { clearAuthToken, setAuthToken } = sdk;
