import React, {useEffect, useMemo, useState} from 'react';
import {Button, Container, Dialog, DialogTitle, Grid, Stack} from '@mui/material';
import {allStages, SSBUStage} from '../types/ssbu/ssbu_stages';
import StageTab from '../components/StageTab';

interface Props {
    currentStages: Array<SSBUStage>,
    groupName: string,
    open: boolean,
    handleAccept: (stages:Array<SSBUStage>) => void,
    handleClose: () => void,
    maxAllowed?: number,
    minAllowed?: number,
    unavailableStages?: Array<SSBUStage>
}

const SelectStagesModal = (props:Props):JSX.Element => {
  const { currentStages, maxAllowed, minAllowed, groupName, handleClose, open, handleAccept, unavailableStages } = props;
  const [selectedStages, setSelectedStages] = useState<Array<SSBUStage>>([]);

  const reachedMax = useMemo(() => Boolean(maxAllowed && selectedStages.length >= maxAllowed), [selectedStages, maxAllowed]);

  const canSubmit = useMemo(() => {
    if (selectedStages.length === 0) {
      return true;
    } else {
      let allow = true;
      if (maxAllowed && selectedStages.length > maxAllowed) {
        allow = false;
      }
      if (minAllowed && selectedStages.length < minAllowed) {
        allow = false;
      }
      return allow;
    }
  }, [selectedStages, maxAllowed, minAllowed]);


  useEffect(() => {
    if (open){
      setSelectedStages(currentStages);
    }
  }, [currentStages, setSelectedStages, open]);

  const onToggleStage = (stage:SSBUStage) => {
    if (!selectedStages.includes(stage)) {
      if (!reachedMax) {
        setSelectedStages((prevState) => [...prevState, stage]);
      }
    } else {
      setSelectedStages((prevState) => prevState.filter(item => item !== stage));
    }
  };

  const onClose = () => {
    handleClose();
    setSelectedStages(currentStages);
  };

  const onAccept = () => {
    handleAccept(selectedStages);
    onClose();
  };

  const quantityHeading = useMemo(() => {
    if (!minAllowed && !maxAllowed) {
      return '';
    } else if (minAllowed && !maxAllowed) {
      return `(Min: ${minAllowed})`;
    } else if (maxAllowed && !minAllowed) {
      return `(Max: ${maxAllowed})`;
    } else {
      if (maxAllowed === minAllowed) {
        return `(Must select ${maxAllowed})`;
      } else {
        return (`(Min: ${minAllowed}, Max: ${maxAllowed}`);
      }
    }
  }, [minAllowed, maxAllowed]);

  const stagesToDisplay = useMemo(() => unavailableStages
    ? allStages.filter((stage) => !unavailableStages.includes(stage))
    : allStages, [unavailableStages]);

  return <Dialog sx={{ marginX: 20, marginY: 10}} maxWidth={false} fullWidth={true} onClose={onClose} open={open}>
    <DialogTitle>Set {groupName} {quantityHeading}</DialogTitle>
    <div style={{ marginBottom: 25}} >
      <Container>
        <Stack marginTop={2}>
          <div style={{ paddingLeft: 15, paddingTop: 5, paddingBottom: 10, paddingRight: 15}}>
            <Grid spacing={2} container alignItems='stretch' justifyContent='space-around'>
              {stagesToDisplay.map(stage => {
                const selected = selectedStages.includes(stage);
                return <StageTab
                  disabled={!selected && reachedMax}
                  key={stage}
                  showToggle={true}
                  toggleSelected={() => onToggleStage(stage)}
                  selected={selected}
                  stage={stage}/>;
              })}
            </Grid>
          </div>
        </Stack>
      </Container>
      <div style={{ display: 'flex', marginTop: 20, width: '100%', justifyContent: 'flex-end' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!canSubmit} onClick={onAccept} sx={{ marginRight: 18}}>OK</Button>
      </div>
    </div>
  </Dialog>;
};

export default SelectStagesModal;
