import React, {useContext, useEffect, useState} from 'react';
import tcsApi from '../api/tc-server/api';
import {RulesetDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {AuthContext} from './AuthContext';

interface RulesetTemplateContextInterface {
    loading: boolean,
    templates: Array<RulesetDTO>,
    refreshTemplates:() => Promise<void>
}

const RulesetTemplateContext = React.createContext<RulesetTemplateContextInterface>({} as RulesetTemplateContextInterface);

interface Props {
    children?: React.ReactNode;
}

const compareTemplates = (a: RulesetDTO, b: RulesetDTO): number => {
  if (!a.owningUserId && b.owningUserId)
    return 1;
  else if (!b.owningUserId && a.owningUserId)
    return -1;
  else
    return `${a.name}`.localeCompare(`${b.name}`);
};

const RulesetTemplateProvider = ({children}:Props):JSX.Element => {

  const { loggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [rulesetTemplate, setRulesetTemplate] = useState<Array<RulesetDTO>>();

  const fetchRulesetTemplates = async () => {
    const rulesets = await tcsApi.rulesets.getTemplates();

    setRulesetTemplate(rulesets.sort((a, b) => compareTemplates(a, b)));
  };

  useEffect(() => {
    const effect = async () => {
      setLoading(true);
      if (loggedIn) {
        await fetchRulesetTemplates();
        setLoading(false);
      } else {
        setRulesetTemplate([]);
      }
    };

    effect();
  }, [loggedIn]);

  const providerValue:RulesetTemplateContextInterface = {
    loading,
    templates: rulesetTemplate ?? [],
    refreshTemplates: fetchRulesetTemplates
  };


  return <RulesetTemplateContext.Provider value={providerValue}>
    {children}
  </RulesetTemplateContext.Provider>;
};

export {RulesetTemplateContext, RulesetTemplateProvider};