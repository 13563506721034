import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  CircularProgress, Grid, LinearProgress,
} from '@mui/material';
import RulesetStageGroup from './RulesetStageGroup';
import {SSBUStage} from '../types/ssbu/ssbu_stages';
import RulesetBanSettings from './RulesetBanSettings';
import {RulesetDTO, StageClause} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import tcsApi from '../api/tc-server/api';
import { ManagedTournamentsContext } from '../context/ManagedTournamentsContext';
import TemplateApplicationManager, {Template} from './TemplateApplicationManager';
import {RulesetTemplateContext} from '../context/RulesetTemplateContext';
import { useSnackAlert } from '../context/SnackAlertContext';
import RulesetAdditionalSettings from './RulesetAdditionalSettings';

export interface BanSettings {
    banCount?: number,
    stageClause?: StageClause
}

interface Props {
    loading: boolean,
    tournamentId: number,
    storedRuleset?: RulesetDTO,
    updateStoredRuleset: (val:RulesetDTO) => void,
    readOnly?: boolean
}

const ManageRuleset = (props:Props):JSX.Element => {
  const { loading, storedRuleset, tournamentId, updateStoredRuleset, readOnly = false } = props;

  const { refreshTournaments } = useContext(ManagedTournamentsContext);
  const { templates } = useContext(RulesetTemplateContext);

  const [starterStages, setStarterStages] = useState<Array<SSBUStage>>([]);
  const [counterStages, setCounterStages] = useState<Array<SSBUStage>>([]);
  const [banSettings, setBanSettings] = useState<BanSettings>({});
  const [bo5Threshold, setBo5Threshold] = useState<number>(8);
  const [stageFirst, setStageFirst] = useState<boolean>(true);
  const [selectedTemplate, setSelectedTemplate] = useState<RulesetDTO>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const openSnackAlert = useSnackAlert();

  const applyTemplate = () => {
    if (selectedTemplate) {
      setStarterStages(selectedTemplate.starterStages);
      setCounterStages(selectedTemplate.counterpickStages);
      setBanSettings({ banCount: selectedTemplate.banCount, stageClause: selectedTemplate.stageClause });
      setBo5Threshold(selectedTemplate.bo5Threshold);
      setStageFirst(selectedTemplate.stageFirst);
    }
  };

  const updateSelectedTemplate = (val:Template | undefined) => setSelectedTemplate(val as RulesetDTO);

  const resetToStored = useCallback(() => {
    if (storedRuleset){
      setStarterStages(storedRuleset.starterStages);
      setCounterStages(storedRuleset.counterpickStages);
      setBanSettings({ banCount: storedRuleset.banCount, stageClause: storedRuleset.stageClause});
      setBo5Threshold(storedRuleset.bo5Threshold);
      setStageFirst(storedRuleset.stageFirst);
    } else {
      setStarterStages([]);
      setCounterStages([]);
      setBanSettings({});
      setBo5Threshold(8);
      setStageFirst(true);
    }
  }, [storedRuleset]);

  const clearRuleset = () => {
    setStarterStages([]);
    setCounterStages([]);
    setBanSettings({});
    setBo5Threshold(8);
    setStageFirst(true);
  };

  const saveChanges = async () => {
    setSaveInProgress(true);
    const ruleset:RulesetDTO = {
      id: storedRuleset?.id,
      starterStages,
      counterpickStages: counterStages,
      banCount: banSettings.banCount ?? 0,
      stageClause: banSettings.stageClause ?? 'DSR',
      videoGame: 'SSBU',
      isTemplate: false,
      tournamentId,
      bo5Threshold: bo5Threshold ?? 0,
      stageFirst
    };

    try {
      const saved = await tcsApi.rulesets.save(ruleset);
      updateStoredRuleset(saved);
      setSelectedTemplate(undefined);
      await refreshTournaments();
      openSnackAlert({
        message: { text: 'Ruleset updated!'},
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating ruleset', err);
      openSnackAlert({
        message: { text: 'Error updating ruleset' },
        severity: 'error'
      });

    } finally {
      setSaveInProgress(false);
    }
  };

  useEffect(() => {
    resetToStored();
  }, [resetToStored]);

  const updateBanCount = (val:number) => setBanSettings((prevState) => ({ ...prevState, banCount: val }));
  const updateStageClause = (val:StageClause) => setBanSettings((prevState) => ({ ...prevState, stageClause: val }));

  const handleUpdateStarters = (stages:Array<SSBUStage>) => {
    setStarterStages(stages);
    setCounterStages((prevState) => prevState.filter((stage) => !stages.includes(stage)));
  };

  const handleUpdateCounterpicks = (stages:Array<SSBUStage>) => setCounterStages(stages);

  const handleUpdateBo5Threshold = (val:number) => setBo5Threshold(val);

  const handleUpdateStageFirst = (val:boolean) => setStageFirst(val);

  if (loading) {
    return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress/>
    </div>;
  }

  return <React.Fragment>
    {!readOnly && <><TemplateApplicationManager
      availableTemplates={templates as Array<Template>}
      handleApplyTemplate={applyTemplate}
      handleUpdateSelectedTemplate={updateSelectedTemplate}
      selectedTemplate={selectedTemplate as Template}
    />
    <Button onClick={clearRuleset} sx={{ margin: 1}}>Clear Ruleset</Button>
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <Button disabled={saveInProgress} onClick={saveChanges}>Save</Button>
      </Grid>
    </Grid></>
    }
    {saveInProgress && <LinearProgress sx={{ marginX: 2}}/>}
    <RulesetStageGroup
      minAllowed={5}
      maxAllowed={5}
      handleUpdateStages={handleUpdateStarters}
      groupName='Starter Stages'
      stages={starterStages}
      readOnly={readOnly}
    />
    <RulesetStageGroup
      handleUpdateStages={handleUpdateCounterpicks}
      groupName='Counterpick Stages (Optional)'
      stages={counterStages}
      unavailableStages={starterStages}
      readOnly={readOnly}
    />
    <RulesetBanSettings
      settings={banSettings}
      handleUpdateBanCount={updateBanCount}
      handleUpdateStageClause={updateStageClause}
      readOnly={readOnly}
    />
    <RulesetAdditionalSettings
      bo5Threshold={bo5Threshold}
      handleUpdateBo5Threshold={handleUpdateBo5Threshold}
      stageFirst={stageFirst}
      handleUpdateStageFirst={handleUpdateStageFirst}
      readOnly={readOnly}
    />

  </React.Fragment>;
};

export default ManageRuleset;
