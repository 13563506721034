import React, { useContext, useMemo, useState } from 'react';
import { MatchDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {
  Card,
  Divider,
  Grid, IconButton, Menu, MenuItem,
  Paper,
  Typography
} from '@mui/material';
import {CompletedMatchScore} from './InProgressTournament';
import { TournamentEntrantsContext } from '../context/TournamentEntrantContext';
import CardEntrantRow from './CardEntrantRow';
import { MoreVert } from '@mui/icons-material';
import ConfirmResetMatchModal from '../modals/ConfirmResetMatchModal';

interface Props {
    entrantIds: Array<number>;
    matchScore: CompletedMatchScore;
    match: MatchDTO;
    handleRefreshCompletedMatches?: () => Promise<void>;
}

const CompletedMatchCard = (props:Props):JSX.Element => {
  const { entrantIds, match, matchScore, handleRefreshCompletedMatches } = props;
  const { entrantDetailsMap } = useContext(TournamentEntrantsContext);
  const [resetSetModalOpen, setResetSetModalOpen] = useState(false);

  // Start Menu Control
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
    // End Menu Control

  const handleClickResetSetMenuOption = () => {
    setResetSetModalOpen(true);
    handleMenuClose();
  };

  const entrant1Details = useMemo(() => entrantDetailsMap.get(entrantIds[0])!, [entrantDetailsMap, entrantIds]);
  const entrant2Details = useMemo(() => entrantDetailsMap.get(entrantIds[1])!, [entrantDetailsMap, entrantIds]);

  const p1Score = matchScore.scores.find(score => score.entrantId === entrant1Details.id)?.score ?? 0;
  const p2Score = matchScore.scores.find(score => score.entrantId === entrant2Details.id)?.score ?? 0;

  let dqPlayerPosition = -1;
  if (match.gameIds.length === 0) {
    dqPlayerPosition = match.winningEntrantId === entrant1Details.id ? 2 : 1;
  }

  let p1ScoreText = p1Score.toString();
  let p2ScoreText = p2Score.toString();
  if (dqPlayerPosition === 1) {
    p1ScoreText = 'DQ';
    p2ScoreText = 'W';
  } else if (dqPlayerPosition === 2) {
    p1ScoreText = 'W';
    p2ScoreText = 'DQ';
  }

  const menuId = `match-${match.id}-menu`;

  const scoreColor = (entrantId: number) => entrantId === match.winningEntrantId ? 'green' : 'red';

  return <React.Fragment>
    <ConfirmResetMatchModal
      handleClose={() => setResetSetModalOpen(false)}
      open={resetSetModalOpen}
      matchId={match.id}
      isComplete={true}
      handleRefreshCompletedMatches={handleRefreshCompletedMatches!}
    />
    <Card variant='outlined' sx={{ backgroundColor: '#1E1E1E', width: 250, paddingX: 1 }}>
      {
        handleRefreshCompletedMatches
          ? <Grid container direction='column'>
            <IconButton
              sx={{ alignSelf: 'flex-end' }}
              aria-controls={menuOpen ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <MoreVert/>
            </IconButton>
          </Grid>
          : <div style={{ height: 10 }}></div>
      }
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleClickResetSetMenuOption}>Reset Match</MenuItem>
      </Menu>
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item xs={9} sx={{ marginLeft: 1 }}>
          <CardEntrantRow entrantDetails={entrant1Details} />
        </Grid>
        <Paper elevation={8} sx={{
          backgroundColor: scoreColor(entrantIds[0]),
          width: 25,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 1
        }}><Typography variant='subtitle1' textAlign='center'><b>{p1ScoreText}</b></Typography></Paper>
      </Grid>
      <Divider>vs</Divider>
      <Grid container direction='row' justifyContent='space-between' marginBottom={1}>
        <Grid item xs={9} sx={{ marginLeft: 1 }}>
          <CardEntrantRow entrantDetails={entrant2Details} />
        </Grid>
        <Paper elevation={8} sx={{
          backgroundColor: scoreColor(entrantIds[1]),
          width: 25,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 1
        }}><Typography variant='subtitle1' textAlign='center'><b>{p2ScoreText}</b></Typography></Paper>
      </Grid>
    </Card>
  </React.Fragment>;
};

export default CompletedMatchCard;
