import React from 'react';
import {Dialog, DialogTitle} from '@mui/material';
import ManageRuleset from '../components/ManageRuleset';
import {RulesetDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';

interface Props {
    handleClose: () => void
    open: boolean,
    loading: boolean,
    tournamentId: number,
    storedRuleset?: RulesetDTO,
    updateStoredRuleset: (val:RulesetDTO) => void,
    readOnly?: boolean
}

const ManageRulesetModal = (props:Props):JSX.Element => {
  const { handleClose, open, loading, storedRuleset, updateStoredRuleset, tournamentId, readOnly = false } = props;
  return <Dialog maxWidth={false} open={open} onClose={handleClose}>
    <DialogTitle>{ readOnly ? 'View Ruleset' : 'Manage Ruleset' }</DialogTitle>
    <ManageRuleset
      loading={loading}
      tournamentId={tournamentId}
      storedRuleset={storedRuleset}
      updateStoredRuleset={updateStoredRuleset}
      readOnly={readOnly}
    />
  </Dialog>;
};

export default ManageRulesetModal;
