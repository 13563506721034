import React, { createContext, useState, useContext } from 'react';
import { Snackbar, SnackbarOrigin } from '@mui/material';
import Alert from '@mui/material/Alert';
import { OverridableStringUnion } from '@mui/types';
import { AlertColor, AlertPropsColorOverrides } from '@mui/material/Alert/Alert';

type SnackAlertContextType = (input: OpenSnackAlertInput) => void;

const SnackAlertContext = createContext<SnackAlertContextType>(() => {});

interface SnackAlertProviderProps {
  children: React.ReactNode;
}

type SnackAlertPositionType = 'bottom-left' | 'top-right' | 'top';

interface OpenSnackAlertInput {
  message: {
    text?: string;
    body?: React.ReactNode;
  };
  duration?: number;
  severity?: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
  position?: SnackAlertPositionType;

}

export const SnackAlertProvider = ({ children }: SnackAlertProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [body, setBody] = useState<React.ReactNode | undefined>(undefined);
  const [severity, setSeverity] = useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>('info');
  const [position, setPosition] = useState<SnackbarOrigin>({ vertical: 'top', horizontal: 'center' });
  const [duration, setDuration] = useState(3000);

  const openSnackAlert = ({
    duration = 3000,
    message,
    severity = 'info',
    position = 'top',
  }: OpenSnackAlertInput) => {
    setBody(message.body);
    setMessage(`${message.text}`);
    setDuration(duration);
    setSeverity(severity);
    setOpen(true);

    switch(position) {
    case 'bottom-left':
      setPosition({ vertical: 'bottom', horizontal: 'left' });
      break;
    case 'top-right':
      setPosition({ vertical: 'top', horizontal: 'right' });
      break;
    case 'top':
      setPosition({ vertical: 'top', horizontal: 'center' });
      break;
    default:
      setPosition({ vertical: 'top', horizontal: 'right' });
    }
  };

  const closeSnackAlert = () => {
    setOpen(false);
  };

  return (
    <SnackAlertContext.Provider value={openSnackAlert}>
      {children}
      <Snackbar
        anchorOrigin={position}
        open={open}
        autoHideDuration={duration}
        onClose={closeSnackAlert}
      >
        <Alert
          onClose={closeSnackAlert}
          severity={severity}
          variant="filled"
          sx={{
            width: '100%',
            backgroundColor: severity === 'info' ? 'gray' : undefined
          }}
        >
          { body || message }
        </Alert>
      </Snackbar>
    </SnackAlertContext.Provider>
  );
};

export const useSnackAlert = (): SnackAlertContextType => {
  const openSnackAlert = useContext(SnackAlertContext);
  if (openSnackAlert === undefined) {
    throw new Error('useSnackAlert must be used within a SnackAlertProvider');
  }
  return openSnackAlert;
};