import { clearAuthToken, setAuthToken } from '../api/tc-server/api';
import React, { useEffect, useState } from 'react';
import { auth } from '../api/auth/firebase';
import {onIdTokenChanged, signInWithCustomToken, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import tcsApi from '../api/tc-server/api';
import { BetaAvailabilityResponse, UserDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import getProvider from '../api/auth/oauth/providers';

interface AuthContextInterface {
    user: UserDTO,
    betaAvailability: BetaAvailabilityResponse
    setAuthenticatedUser: (val:UserDTO) => void,
    handleOauthRedirect: (code: string) => Promise<void>,
    initialized: boolean,
    loggedIn: boolean,
    login: (email:string, password:string) => Promise<void>,
    logout: () => Promise<void>,
    isLoading: boolean,
    setLoading: (loading:boolean) => void
}

const AuthContext = React.createContext<AuthContextInterface>({} as AuthContextInterface);

interface Props {
    children?: React.ReactNode;
}

const AuthProvider = ({children}:Props):JSX.Element => {

  const [authenticatedUser, setAuthenticatedUser] = useState<UserDTO>({} as UserDTO);
  const [betaAvailability, setBetaAvailability] = useState<BetaAvailabilityResponse>({} as BetaAvailabilityResponse);
  const [initialized, setInitialized] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(false);
          setAuthToken(token);
          setLoading(true);
          setAuthenticatedUser(await tcsApi.users.me());
          setBetaAvailability(await tcsApi.users.betaAvailability());
          setLoggedIn(true);
        } catch (err) {
          console.error(err);
          setLoggedIn(false);
        } finally {
          setLoading(false);
        }
      } else {
        clearAuthToken();
        setLoggedIn(false);
        setAuthenticatedUser({} as UserDTO);
      }
      setInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  async function login(email: string, password:string): Promise<void> {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoggedIn(true);
    } catch (err) {
      // TODO error message
      setLoading(false);
    }
  }

  async function logout(): Promise<void> {
    setLoading(true);
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleOauthRedirect(code: string): Promise<void> {
    await logout();
    console.log('Logged out.');
    const token = await getProvider('startgg').completeOauthFlow(code);
    await signInWithCustomToken(auth, `${token}`);
  }

  const providerValue:AuthContextInterface = {
    user: authenticatedUser,
    betaAvailability,
    initialized,
    loggedIn,
    handleOauthRedirect,
    setAuthenticatedUser,
    isLoading: loading,
    setLoading,
    login,
    logout
  };


  return <AuthContext.Provider value={providerValue}>
    {children}
  </AuthContext.Provider>;
};

export { AuthContext, AuthProvider };