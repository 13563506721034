const env = {
  api: {
    tcsUrl: process.env.REACT_APP_TCS_API_URL,
    matchEngineStompEndpoint: process.env.REACT_APP_MATCH_ENGINE_STOMP_ENDPOINT,
    tcAuthUrl: process.env.REACT_APP_TC_AUTH_URL
  },
  oauth: {
    startgg: {
      redirectUrl: process.env.REACT_APP_STARTGG_REDIRECT_URL
    }
  }
};

console.log('ENV: ', env);

export { env };