import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import {BanSettings} from './ManageRuleset';
import {StageClause} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';

interface Props {
    settings: BanSettings,
    handleUpdateBanCount: (val: number) => void,
    handleUpdateStageClause: (val: StageClause) => void,
    readOnly?: boolean
}

interface StageClauseValue {
    key: StageClause,
    value: string
}

const stageClauseValues:Array<StageClauseValue> = [
  { key: 'NO_DSR', value: 'No Stage Clause' },
  { key: 'DSR', value: 'DSR' },
  { key: 'MDSR', value: 'Modified DSR' }
];

const RulesetBanSettings = (props:Props):JSX.Element => {

  const { settings, handleUpdateBanCount, handleUpdateStageClause, readOnly = false } = props;

  return <Paper sx={{ margin: 1, paddingY: 3, paddingX: 1}} elevation={6}>
    <Paper elevation={2} sx={{marginX: 2, padding: 1, paddingTop: 3}}>
      <Typography marginLeft={2} marginBottom={1}><b>Stage Ban Settings:</b></Typography>
      <Grid columns={2} spacing={6} container alignItems='stretch' paddingX={3} paddingY={1}>
        <Grid item xs={1}>
          <TextField
            type='number'
            label='How many stage bans per round?'
            sx={{ width: '100%'}}
            value={settings.banCount ?? ''}
            onChange={(event) => handleUpdateBanCount(Number(event.target.value))}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth>
            <InputLabel>Stage Clause</InputLabel>
            <Select
              name="Stage Clause"
              value={settings.stageClause ?? '' }
              onChange={(event) => handleUpdateStageClause(event.target.value as StageClause)}
              label="Stage Clause"
              disabled={readOnly}
            >
              {stageClauseValues.map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

    </Paper>
  </Paper>;
};

export default RulesetBanSettings;
