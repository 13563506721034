
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { useContext } from 'react';

interface Props {
    children: JSX.Element
}

const RequiresAuth = (props:Props) => {
  const { children } = props;
  const { loggedIn } = useContext(AuthContext);
  const location = useLocation();

  return loggedIn ? children : <Navigate to='/login' state={ { path: location.pathname } }/>;
};

export default RequiresAuth;