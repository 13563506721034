import React from 'react';
import {Box, Button, Fade, Grid} from '@mui/material';
import {Add} from '@mui/icons-material';

interface Props {
    handleAddStation: () => void,
    visible: boolean
}

const AddStationCard = (props:Props):JSX.Element => {
  const { handleAddStation, visible } = props;

  return <Grid item sm={6} md={4} lg={3}>
    <Fade in={visible}>
      <div style={{ display: 'flex', justifyContent: 'center'}}>
        <Button
          onClick={handleAddStation}
          sx={{ borderRadius: 100}}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', color: 'primary.main', borderRadius: 100, border: 2, textDecoration: 'none' }}
            justifyContent='center'
            alignItems='center'
          >
            <Add sx={{ alignSelf: 'center', textAlign: 'center', font: 'inherit', fontSize: 48 }}/>
          </Box>
        </Button>
      </div>
    </Fade>
  </Grid>;
};

export default AddStationCard;
