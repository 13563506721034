import banjo from '../../assets/char_icons/ssbu/banjo.png';
import bayonetta from '../../assets/char_icons/ssbu/bayonetta.png';
import bowser from '../../assets/char_icons/ssbu/bowser.png';
import bowserjr from '../../assets/char_icons/ssbu/bowserjr.png'; 
import byleth from '../../assets/char_icons/ssbu/byleth.png';
import captainfalcon from '../../assets/char_icons/ssbu/captainfalcon.png';
import chrom from '../../assets/char_icons/ssbu/chrom.png';
import cloud from '../../assets/char_icons/ssbu/cloud.png';
import corrin from '../../assets/char_icons/ssbu/corrin.png';
import daisy from '../../assets/char_icons/ssbu/daisy.png';
import darkpit from '../../assets/char_icons/ssbu/darkpit.png';
import darksamus from '../../assets/char_icons/ssbu/darksamus.png';
import diddykong from '../../assets/char_icons/ssbu/diddykong.png';
import doctormario from '../../assets/char_icons/ssbu/doctormario.png';
import donkeykong from '../../assets/char_icons/ssbu/donkeykong.png';
import duckhunt from '../../assets/char_icons/ssbu/duckhunt.png';
import falco from '../../assets/char_icons/ssbu/falco.png';
import fox from '../../assets/char_icons/ssbu/fox.png';
import ganondorf from '../../assets/char_icons/ssbu/ganondorf.png';
import greninja from '../../assets/char_icons/ssbu/greninja.png';
import hero from '../../assets/char_icons/ssbu/hero.png';
import iceclimbers from '../../assets/char_icons/ssbu/iceclimbers.png';
import ike from '../../assets/char_icons/ssbu/ike.png';
import incineroar from '../../assets/char_icons/ssbu/incineroar.png';
import inkling from '../../assets/char_icons/ssbu/inkling.png';
import isabelle from '../../assets/char_icons/ssbu/isabelle.png';
import jigglypuff from '../../assets/char_icons/ssbu/jigglypuff.png';
import joker from '../../assets/char_icons/ssbu/joker.png';
import kazuya from '../../assets/char_icons/ssbu/kazuya.png';
import ken from '../../assets/char_icons/ssbu/ken.png';
import kingdedede from '../../assets/char_icons/ssbu/kingdedede.png';
import kingkrool from '../../assets/char_icons/ssbu/kingkrool.png';
import kirby from '../../assets/char_icons/ssbu/kirby.png';
import link from '../../assets/char_icons/ssbu/link.png';
import littlemac from '../../assets/char_icons/ssbu/littlemac.png';
import lucario from '../../assets/char_icons/ssbu/lucario.png';
import lucas from '../../assets/char_icons/ssbu/lucas.png';
import lucina from '../../assets/char_icons/ssbu/lucina.png';
import luigi from '../../assets/char_icons/ssbu/luigi.png';
import mario from '../../assets/char_icons/ssbu/mario.png';
import marth from '../../assets/char_icons/ssbu/marth.png';
import megaman from '../../assets/char_icons/ssbu/megaman.png';
import metaknight from '../../assets/char_icons/ssbu/metaknight.png';
import mewtwo from '../../assets/char_icons/ssbu/mewtwo.png';
import miibrawler from '../../assets/char_icons/ssbu/miibrawler.png';
import miigunner from '../../assets/char_icons/ssbu/miigunner.png';
import miiswordfighter from '../../assets/char_icons/ssbu/miiswordfighter.png';
import minmin from '../../assets/char_icons/ssbu/minmin.png';
import mrgameandwatch from '../../assets/char_icons/ssbu/mrgameandwatch.png';
import ness from '../../assets/char_icons/ssbu/ness.png';
import olimar from '../../assets/char_icons/ssbu/olimar.png';
import pacman from '../../assets/char_icons/ssbu/pacman.png';
import palutena from '../../assets/char_icons/ssbu/palutena.png';
import peach from '../../assets/char_icons/ssbu/peach.png';
import pichu from '../../assets/char_icons/ssbu/pichu.png';
import pikachu from '../../assets/char_icons/ssbu/pikachu.png';
import piranhaplant from '../../assets/char_icons/ssbu/piranhaplant.png';
import pit from '../../assets/char_icons/ssbu/pit.png';
import pokemontrainer from '../../assets/char_icons/ssbu/pokemontrainer.png';
import pyra from '../../assets/char_icons/ssbu/pyra.png';
import random from '../../assets/char_icons/ssbu/random.png';
import richter from '../../assets/char_icons/ssbu/richter.png';
import ridley from '../../assets/char_icons/ssbu/ridley.png';
import rob from '../../assets/char_icons/ssbu/rob.png';
import robin from '../../assets/char_icons/ssbu/robin.png';
import rosalina from '../../assets/char_icons/ssbu/rosalina.png';
import roy from '../../assets/char_icons/ssbu/roy.png';
import ryu from '../../assets/char_icons/ssbu/ryu.png';
import samus from '../../assets/char_icons/ssbu/samus.png';
import sephiroth from '../../assets/char_icons/ssbu/sephiroth.png';
import sheik from '../../assets/char_icons/ssbu/sheik.png';
import shulk from '../../assets/char_icons/ssbu/shulk.png';
import simon from '../../assets/char_icons/ssbu/simon.png';
import snake from '../../assets/char_icons/ssbu/snake.png';
import sonic from '../../assets/char_icons/ssbu/sonic.png';
import sora from '../../assets/char_icons/ssbu/sora.png';
import steve from '../../assets/char_icons/ssbu/steve.png';
import terry from '../../assets/char_icons/ssbu/terry.png';
import toonlink from '../../assets/char_icons/ssbu/toonlink.png';
import villager from '../../assets/char_icons/ssbu/villager.png';
import wario from '../../assets/char_icons/ssbu/wario.png';
import wiifittrainer from '../../assets/char_icons/ssbu/wiifittrainer.png';
import wolf from '../../assets/char_icons/ssbu/wolf.png';
import yoshi from '../../assets/char_icons/ssbu/yoshi.png';
import younglink from '../../assets/char_icons/ssbu/younglink.png';
import zelda from '../../assets/char_icons/ssbu/zelda.png';
import zerosuitsamus from '../../assets/char_icons/ssbu/zerosuitsamus.png';


export interface CharacterData {
    id: number
    name: string
    abbreviatedName?: string
    icon: string
}

export type SSBUCharacter =
    'MARIO' | 'DONKEY_KONG' | 'LINK' | 'SAMUS' | 'DARK_SAMUS' | 'YOSHI'| 'KIRBY' | 'FOX' |
    'PIKACHU' | 'LUIGI' | 'NESS' | 'CAPTAIN_FALCON' | 'JIGGLYPUFF' | 'PEACH' | 'DAISY' |
    'BOWSER' | 'ICE_CLIMBERS' | 'SHEIK' | 'ZELDA' | 'DR_MARIO' | 'PICHU' | 'FALCO' |
    'MARTH' | 'LUCINA' | 'YOUNG_LINK' | 'GANONDORF' | 'MEWTWO' | 'ROY' | 'CHROM' |
    'MR_GAME_AND_WATCH' | 'METAKNIGHT' | 'PIT' | 'DARK_PIT' | 'ZERO_SUIT_SAMUS' | 'WARIO' | 'SNAKE' |
    'IKE' | 'POKEMON_TRAINER' | 'DIDDY_KONG' | 'LUCAS' | 'SONIC' | 'KING_DEDEDE' | 'OLIMAR' |
    'LUCARIO' | 'ROB' | 'TOON_LINK' | 'WOLF' | 'VILLAGER' | 'MEGAMAN' | 'WII_FIT_TRAINER' |
    'ROSALINA_AND_LUMA' | 'LITTLE_MAC' | 'GRENINJA' | 'MII_BRAWLER' | 'MII_SWORDFIGHTER' | 'MII_GUNNER' | 'PALUTENA' |
    'PAC_MAN' | 'ROBIN' | 'SHULK' | 'BOWSER_JR' | 'DUCK_HUNT' | 'RYU' | 'KEN' |
    'CLOUD' | 'CORRIN' | 'BAYONETTA' | 'INKLING' | 'RIDLEY' | 'SIMON' | 'RICHTER' |
    'KING_K_ROOL' | 'ISABELLE' | 'INCINEROAR' | 'PIRANHA_PLANT' | 'JOKER' | 'HERO' | 'BANJO_AND_KAZOOIE' |
    'TERRY' | 'BYLETH' | 'MIN_MIN' | 'STEVE' | 'SEPHIROTH' | 'PYRA' | 'KAZUYA' | 'SORA'

export const allCharacters:Array<SSBUCharacter> = [
  'MARIO' , 'DONKEY_KONG' , 'LINK' , 'SAMUS' , 'DARK_SAMUS' , 'YOSHI', 'KIRBY' , 'FOX' ,
  'PIKACHU' , 'LUIGI' , 'NESS' , 'CAPTAIN_FALCON' , 'JIGGLYPUFF' , 'PEACH' , 'DAISY' ,
  'BOWSER' , 'ICE_CLIMBERS' , 'SHEIK' , 'ZELDA' , 'DR_MARIO' , 'PICHU' , 'FALCO' ,
  'MARTH' , 'LUCINA' , 'YOUNG_LINK' , 'GANONDORF' , 'MEWTWO' , 'ROY' , 'CHROM' ,
  'MR_GAME_AND_WATCH' , 'METAKNIGHT' , 'PIT' , 'DARK_PIT' , 'ZERO_SUIT_SAMUS' , 'WARIO' , 'SNAKE' ,
  'IKE' , 'POKEMON_TRAINER' , 'DIDDY_KONG' , 'LUCAS' , 'SONIC' , 'KING_DEDEDE' , 'OLIMAR' ,
  'LUCARIO' , 'ROB' , 'TOON_LINK' , 'WOLF' , 'VILLAGER' , 'MEGAMAN' , 'WII_FIT_TRAINER' ,
  'ROSALINA_AND_LUMA' , 'LITTLE_MAC' , 'GRENINJA' , 'PALUTENA' ,
  'PAC_MAN' , 'ROBIN' , 'SHULK' , 'BOWSER_JR' , 'DUCK_HUNT' , 'RYU' , 'KEN' ,
  'CLOUD' , 'CORRIN' , 'BAYONETTA' , 'INKLING' , 'RIDLEY' , 'SIMON' , 'RICHTER' ,
  'KING_K_ROOL' , 'ISABELLE' , 'INCINEROAR' , 'PIRANHA_PLANT' , 'JOKER' , 'HERO' , 'BANJO_AND_KAZOOIE' ,
  'TERRY' , 'BYLETH' , 'MIN_MIN' , 'STEVE' , 'SEPHIROTH' , 'PYRA', 'KAZUYA', 'SORA' , 'MII_BRAWLER' , 'MII_SWORDFIGHTER' , 'MII_GUNNER'
];

export const ssbuCharacters: Record<SSBUCharacter, CharacterData> = {
  'MARIO': {id: 1, name: 'Mario', icon: mario},
  'DONKEY_KONG': {id: 2, name: 'Donkey Kong', abbreviatedName: 'DK', icon: donkeykong},
  'LINK': {id: 3, name: 'Link', icon: link},
  'SAMUS': {id: 4, name: 'Samus', icon: samus},
  'DARK_SAMUS': {id: 5, name: 'Dark Samus', abbreviatedName: 'D. Samus', icon: darksamus},
  'YOSHI': {id: 6, name: 'Yoshi', icon: yoshi},
  'KIRBY': {id: 7, name: 'Kirby', icon: kirby},
  'FOX': {id: 8, name: 'Fox', icon: fox},
  'PIKACHU': {id: 9, name: 'Pikachu', icon: pikachu},
  'LUIGI': {id: 10, name: 'Luigi', icon: luigi},
  'NESS': {id: 11, name: 'Ness', icon: ness},
  'CAPTAIN_FALCON': {id: 12, name: 'Captain Falcon', abbreviatedName: 'C. Falcon', icon: captainfalcon},
  'JIGGLYPUFF': {id: 13, name: 'Jigglypuff', icon: jigglypuff},
  'PEACH': {id: 14, name: 'Peach', icon: peach},
  'DAISY': {id: 15, name: 'Daisy', icon: daisy},
  'BOWSER': {id: 16, name: 'Bowser', icon: bowser},
  'ICE_CLIMBERS': {id: 17, name: 'Ice Climbers', abbreviatedName: 'ICs', icon: iceclimbers},
  'SHEIK': {id: 18, name: 'Sheik', icon: sheik},
  'ZELDA': {id: 19, name: 'Zelda', icon: zelda},
  'DR_MARIO': {id: 20, name: 'Dr. Mario', icon: doctormario},
  'PICHU': {id: 21, name: 'Pichu', icon: pichu},
  'FALCO': {id: 22, name: 'Falco', icon: falco},
  'MARTH': {id: 23, name: 'Marth', icon: marth},
  'LUCINA': {id: 24, name: 'Lucina', icon: lucina},
  'YOUNG_LINK': {id: 25, name: 'Young Link', abbreviatedName: 'Y. Link', icon: younglink},
  'GANONDORF': {id: 26, name: 'Ganondorf', icon: ganondorf},
  'MEWTWO': {id: 27, name: 'Mewtwo', icon: mewtwo},
  'ROY': {id: 28, name: 'Roy', icon: roy},
  'CHROM': {id: 29, name: 'Chrom', icon: chrom},
  'MR_GAME_AND_WATCH': {id: 30, name: 'Mr. Game and Watch', abbreviatedName: 'Mr. G&W', icon: mrgameandwatch},
  'METAKNIGHT': {id: 31, name: 'Metaknight', abbreviatedName: 'MK', icon: metaknight},
  'PIT': {id: 32, name: 'Pit', icon: pit},
  'DARK_PIT': {id: 33, name: 'Dark Pit', abbreviatedName: 'D. Pit', icon: darkpit},
  'ZERO_SUIT_SAMUS': {id: 34, name: 'Zero Suit Samus', abbreviatedName: 'ZSS', icon: zerosuitsamus},
  'WARIO': {id: 35, name: 'Wario', icon: wario},
  'SNAKE': {id: 36, name: 'Snake', icon: snake},
  'IKE': {id: 37, name: 'Ike', icon: ike},
  'POKEMON_TRAINER': {id: 38, name: 'Pokemon Trainer', abbreviatedName: 'PT', icon: pokemontrainer},
  'DIDDY_KONG': {id: 39, name: 'Diddy Kong', abbreviatedName: 'Diddy', icon: diddykong},
  'LUCAS': {id: 40, name: 'Lucas', icon: lucas},
  'SONIC': {id: 41, name: 'Sonic', icon: sonic},
  'KING_DEDEDE': {id: 42, name: 'King Dedede', abbreviatedName: 'Dedede', icon: kingdedede},
  'OLIMAR': {id: 43, name: 'Olimar', icon: olimar},
  'LUCARIO': {id: 44, name: 'Lucario', icon: lucario},
  'ROB': {id: 45, name: 'R.O.B', icon: rob},
  'TOON_LINK': {id: 46, name: 'Toon Link', icon: toonlink},
  'WOLF': {id: 47, name: 'Wolf', icon: wolf},
  'VILLAGER': {id: 48, name: 'Villager', icon: villager},
  'MEGAMAN': {id: 49, name: 'Megaman', icon: megaman},
  'WII_FIT_TRAINER': {id: 50, name: 'Wii Fit Trainer', abbreviatedName: 'WFT', icon: wiifittrainer},
  'ROSALINA_AND_LUMA': {id: 51, name: 'Rosalina & Luma', abbreviatedName: 'Rosalina', icon: rosalina},
  'LITTLE_MAC': {id: 52, name: 'Little Mac', icon: littlemac},
  'GRENINJA': {id: 53, name: 'Greninja', icon: greninja},
  'MII_BRAWLER': {id: 54, name: 'Mii Brawler', icon: miibrawler},
  'MII_SWORDFIGHTER': {id: 55, name: 'Mii Swordfighter', abbreviatedName: 'Mii Sword', icon: miiswordfighter},
  'MII_GUNNER': {id: 56, name: 'Mii Gunner', icon: miigunner},
  'PALUTENA': {id: 57, name: 'Palutena', icon: palutena},
  'PAC_MAN': {id: 58, name: 'Pac-Man', icon: pacman},
  'ROBIN': {id: 59, name: 'Robin', icon: robin},
  'SHULK': {id: 60, name: 'Shulk', icon: shulk},
  'BOWSER_JR': {id: 61, name: 'Bowser Jr.', icon: bowserjr},
  'DUCK_HUNT': {id: 62, name: 'Duck Hunt', icon: duckhunt},
  'RYU': {id: 63, name: 'Ryu', icon: ryu},
  'KEN': {id: 64, name: 'Ken', icon: ken},
  'CLOUD': {id: 65, name: 'Cloud', icon: cloud},
  'CORRIN': {id: 66, name: 'Corrin', icon: corrin},
  'BAYONETTA': {id: 67, name: 'Bayonetta', icon: bayonetta},
  'INKLING': {id: 68, name: 'Inkling', icon: inkling},
  'RIDLEY': {id: 69, name: 'Ridley', icon: ridley},
  'SIMON': {id: 70, name: 'Simon', icon: simon},
  'RICHTER': {id: 71, name: 'Richter', icon: richter},
  'KING_K_ROOL': {id: 72, name: 'King K. Rool', abbreviatedName: 'K. Rool', icon: kingkrool},
  'ISABELLE': {id: 73, name: 'Isabelle', icon: isabelle},
  'INCINEROAR': {id: 74, name: 'Incineroar', icon: incineroar},
  'PIRANHA_PLANT': {id: 75, name: 'Piranha Plant', abbreviatedName: 'Plant', icon: piranhaplant},
  'JOKER': {id: 76, name: 'Joker', icon: joker},
  'HERO': {id: 77, name: 'Hero', icon: hero},
  'BANJO_AND_KAZOOIE': {id: 78, name: 'Banjo & Kazooie', abbreviatedName: 'Banjo', icon: banjo},
  'TERRY': {id: 79, name: 'Terry', icon: terry},
  'BYLETH': {id: 80, name: 'Byleth', icon: byleth},
  'MIN_MIN': {id: 81, name: 'Min Min', icon: minmin},
  'STEVE': {id: 82, name: 'Steve', icon: steve},
  'SEPHIROTH': {id: 83, name: 'Sephiroth', icon: sephiroth},
  'PYRA': {id: 84, name: 'Pyra', icon: pyra},
  'KAZUYA': {id: 85, name: 'Kazuya', icon: kazuya},
  'SORA': {id: 86, name: 'Sora', icon: sora}
};

export const ssbuCharacterImages = [
  banjo, bayonetta, bowser, bowserjr, byleth, captainfalcon, chrom, cloud, corrin, daisy, darkpit,
  darksamus, diddykong, doctormario, donkeykong, duckhunt, falco, fox, ganondorf, greninja, hero,
  iceclimbers, ike, incineroar, inkling, isabelle, jigglypuff, joker, kazuya, ken, kingdedede,
  kingkrool, kirby, link, littlemac, lucario, lucas, lucina, luigi, mario, marth, megaman, metaknight,
  mewtwo, miibrawler, miigunner, miiswordfighter, minmin, mrgameandwatch, ness, olimar, pacman,
  palutena, peach, pichu, pikachu, piranhaplant, pit, pokemontrainer, pyra, random, richter, ridley,
  rob, robin, rosalina, roy, ryu, samus, sephiroth, sheik, shulk, simon, snake, sonic, sora, steve,
  terry, toonlink, villager, wario, wiifittrainer, wolf, yoshi, younglink, zelda, zerosuitsamus
];

