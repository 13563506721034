import { Button, CircularProgress, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  GameDTO,
  MatchDTO,
} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import tcsApi from '../api/tc-server/api';
import CompletedMatches from './CompletedMatches';
import ConfirmResetBracketModal from '../modals/ConfirmResetBracketModal';
import { TournamentEntrantsContext } from '../context/TournamentEntrantContext';

interface Props {
  tournamentId: number;
}

interface EntrantScore {
  entrantId: number,
  score: number
}

export interface CompletedMatchScore {
  matchId: number,
  scores: Array<EntrantScore>
}

const getScoreForMatch = async (match: MatchDTO, games?: GameDTO[]): Promise<CompletedMatchScore> => {
  if (!games) {
    games = await tcsApi.games.getGamesInMatch(match.id);
  }
  const scoreMap = new Map();
  match.entrantIds.forEach(entrantId => scoreMap.set(entrantId, 0));
  games.forEach(game => {
    const {winningEntrantId} = game;
    const currentValue = scoreMap.get(winningEntrantId);
    scoreMap.set(winningEntrantId, currentValue + 1);
  });

  const scores: Array<EntrantScore> = [];
  scoreMap.forEach((score, entrantId) => scores.push({entrantId, score}));
  return {matchId: match.id, scores};
};

const CompletedTournament = (props: Props): JSX.Element => {
  const { tournamentId} = props;

  const [resetBracketModalOpen, setResetBracketModalOpen] = useState(false);
  const [completedMatches, setCompletedMatches] = useState<Array<MatchDTO>>();
  const [completedMatchScores, setCompletedMatchScores] = useState<Array<CompletedMatchScore>>([]);

  const { loading: entrantsLoading} = useContext(TournamentEntrantsContext);
  useEffect(() => {
    const effect = async () => {
      const tournamentMatches = await tcsApi.matches.getTournamentMatches(tournamentId);
      setCompletedMatches(tournamentMatches);
      const { gameMap } = await tcsApi.games.getBulkGames({
        matchIds: tournamentMatches.map(match => match.id)
      });
      tournamentMatches.forEach(match => {
        getScoreForMatch(match, gameMap[match.id]).then(score => setCompletedMatchScores(prevState => [...prevState, score]));
      });
    };
    effect();
  }, [tournamentId]);


  if (!completedMatches || entrantsLoading) {
    return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress/>
    </div>;
  }

  return <React.Fragment>
    <ConfirmResetBracketModal
      handleClose={() => setResetBracketModalOpen(false)}
      open={resetBracketModalOpen}
      tournamentId={tournamentId}
    />
    <Grid container direction="row" justifyContent='center' marginBottom={3}>
      <Grid item xs={4} sx={{textAlign: 'center'}}>
        <Button color='error' onClick={() => setResetBracketModalOpen(true)}>Reset Bracket</Button>
      </Grid>
    </Grid>
    <CompletedMatches
      matches={completedMatches}
      scores={completedMatchScores}
    />
  </React.Fragment>;
};

export default CompletedTournament;
