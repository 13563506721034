import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { CircularProgress } from '@mui/material';



const OAuthRouteHandler = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleOauthRedirect, logout, setLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const effect = async () => {
      setLoading(true);
      if (searchParams.has('code')) {
        const code = searchParams.get('code') || '';
        searchParams.delete('code');
        await logout();
        setSearchParams(searchParams);
        await handleOauthRedirect(code);
        navigate('/', { replace: true });
      }
    };
    void effect();
  }, []);


  return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
    <CircularProgress/>
  </div>;

};

export default OAuthRouteHandler;
