import React, {useContext, useEffect, useState} from 'react';
import tcsApi from '../api/tc-server/api';
import {StationGroupDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {AuthContext} from './AuthContext';

interface StationGroupTemplateContextInterface {
    loading: boolean,
    templates: Array<StationGroupDTO>,
    refreshTemplates:() => Promise<void>
}

const StationGroupTemplateContext = React.createContext<StationGroupTemplateContextInterface>({} as StationGroupTemplateContextInterface);

interface Props {
    children?: React.ReactNode;
}

const compareTemplates = (a: StationGroupDTO, b: StationGroupDTO): number => {
  if (!a.owningUserId && b.owningUserId)
    return 1;
  else if (!b.owningUserId && a.owningUserId)
    return -1;
  else
    return `${a.name}`.localeCompare(`${b.name}`);
};

const StationGroupTemplateProvider = ({children}:Props):JSX.Element => {

  const { loggedIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [stationGroupTemplate, setStationGroupTemplate] = useState<Array<StationGroupDTO>>();

  const fetchStationGroupTemplates = async () => {
    const stationGroups = await tcsApi.stations.getTemplates();

    setStationGroupTemplate(stationGroups.sort((a, b) => compareTemplates(a, b)));
  };

  useEffect(() => {
    const effect = async () => {
      setLoading(true);
      if (loggedIn) {
        await fetchStationGroupTemplates();
        setLoading(false);
      } else {
        setStationGroupTemplate([]);
      }
    };

    effect();
  }, [loggedIn]);

  const providerValue:StationGroupTemplateContextInterface = {
    loading,
    templates: stationGroupTemplate ?? [],
    refreshTemplates: fetchStationGroupTemplates
  };


  return <StationGroupTemplateContext.Provider value={providerValue}>
    {children}
  </StationGroupTemplateContext.Provider>;
};

export {StationGroupTemplateContext, StationGroupTemplateProvider};