import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Client, IMessage, StompSubscription } from '@stomp/stompjs';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { auth } from '../api/auth/firebase';
import { env } from '../config/env';
import ManageRulesetModal from '../modals/ManageRulesetModal';
import ManageStationsModal from '../modals/MangeStationsModal';
import {
  GameDTO,
  MatchDTO,
  RulesetDTO,
  SSBUCharacter,
  SSBUStage,
  StationGroupDTO, TournamentEntrantDTO
} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import tcsApi from '../api/tc-server/api';
import UncalledMatches from './UncalledMatches';
import InProgressMatches from './InProgressMatches';
import CompletedMatches from './CompletedMatches';
import MatchGroupHeaderCard from './MatchGroupHeaderCard';
import { TournamentEntrantsContext } from '../context/TournamentEntrantContext';
import ConfirmResetBracketModal from '../modals/ConfirmResetBracketModal';
import { useSnackAlert } from '../context/SnackAlertContext';
import StationAvailability from './StationAvailability';
import { ManagedTournamentsContext } from '../context/ManagedTournamentsContext';

interface Props {
  loading: boolean,
  tournamentId: number,
  storedRuleset?: RulesetDTO,
  updateStoredRuleset: (val: RulesetDTO) => void,
  storedStationGroup?: StationGroupDTO,
  updateStoredStationGroup: (val: StationGroupDTO) => void
}

export interface CharacterSelection {
  entrantId: number,
  character: SSBUCharacter
}

interface EntrantScore {
  entrantId: number,
  score: number
}

export interface CompletedMatchScore {
  matchId: number,
  scores: Array<EntrantScore>
}

export type MatchStatus = 'BLIND_CHARACTER' | 'COMPLETE' | 'COUNTER_CHARACTER' | 'COUNTER_STAGE' |
  'DOUBLE_BLIND' | 'PENDING_REPORT' | 'STAGE_BAN' | 'STAGE_STRIKING'

export interface MatchStateResponse {
  matchId: number,
  actionOn: Array<number>,
  characterSelections: Array<CharacterSelection>,
  currentStage: SSBUStage,
  gameNumber: number,
  score: Array<EntrantScore>,
  stageCatalogue: Array<SSBUStage>,
  matchStatus: MatchStatus,
  unavailableStages: Array<SSBUStage>,
  syncedEntrants: Array<number>;
  createdAt: string;
  lastStateChangeAt: string;
}

interface MatchSubscription {
  matchId: number,
  subscription: StompSubscription
}

export interface GroupConfigSet {
  headingName: string,
  borderColor: string,
  checkBoxColor: CheckboxColor
}

export type CheckboxColor = 'warning' | 'success' | 'primary';

interface GroupConfigs {
  uncalledGroupConfigSet: GroupConfigSet,
  inProgressGroupConfigSet: GroupConfigSet,
  completedGroupConfigSet: GroupConfigSet
}

type TournamentSubscriptionMessage =
  | { type: 'checkins', data: TournamentEntrantDTO }
  | { type: 'matches', data: MatchDTO[] };

export const groupConfigs: GroupConfigs = {
  uncalledGroupConfigSet: {
    headingName: 'Uncalled Matches',
    borderColor: 'gold',
    checkBoxColor: 'warning'
  },
  inProgressGroupConfigSet: {
    headingName: 'In Progress Matches',
    borderColor: '#005db0',
    checkBoxColor: 'primary'
  },
  completedGroupConfigSet: {
    headingName: 'Completed Matches',
    borderColor: 'green',
    checkBoxColor: 'success'
  }
};


const getScoreForMatch = async (match: MatchDTO, games?: GameDTO[]): Promise<CompletedMatchScore> => {
  if (!games) {
    games = await tcsApi.games.getGamesInMatch(match.id);
  }
  const scoreMap = new Map();
  match.entrantIds.forEach(entrantId => scoreMap.set(entrantId, 0));
  games.forEach(game => {
    const {winningEntrantId} = game;
    const currentValue = scoreMap.get(winningEntrantId);
    scoreMap.set(winningEntrantId, currentValue + 1);
  });

  const scores: Array<EntrantScore> = [];
  scoreMap.forEach((score, entrantId) => scores.push({entrantId, score}));
  return {matchId: match.id, scores};
};

const InProgressTournament = (props: Props): JSX.Element => {
  const {
    loading,
    tournamentId,
    storedRuleset,
    updateStoredRuleset,
    storedStationGroup,
    updateStoredStationGroup
  } = props;

  const {entrantDetailsMap, loading: entrantsLoading, setEntrantCheckedInStatus} = useContext(TournamentEntrantsContext);
  const { refreshTournaments } = useContext(ManagedTournamentsContext);

  const [matches, setMatches] = useState<Array<MatchDTO>>([]);
  const [manageRulesetModalOpen, setManageRulesetModalOpen] = useState(false);
  const [manageStationGroupModalOpen, setManageStationGroupModalOpen] = useState(false);
  const [resetBracketModalOpen, setResetBracketModalOpen] = useState(false);
  const [matchSubscriptions, setMatchSubscriptions] = useState<Array<MatchSubscription>>([]);
  const [inProgressMatchStates, setInProgressMatchStates] = useState<Array<MatchStateResponse>>([]);
  const [showUncalledChecked, setShowUncalledChecked] = useState(true);
  const [showInProgressChecked, setShowInProgressChecked] = useState(true);
  const [showCompletedChecked, setShowCompletedChecked] = useState(false);
  const [showIncompleteChecked, setShowIncompleteChecked] = useState(false);
  const [completedMatches, setCompletedMatches] = useState<Array<MatchDTO>>([]);
  const [completedMatchScores, setCompletedMatchScores] = useState<Array<CompletedMatchScore>>([]);

  const toggleShowUncalled = () => setShowUncalledChecked((prevState) => !prevState);
  const toggleShowInProgress = () => setShowInProgressChecked((prevState) => !prevState);
  const toggleShowCompleted = () => setShowCompletedChecked((prevState) => !prevState);
  const toggleShowIncomplete = () => setShowIncompleteChecked((prevState) => !prevState);

  const openSnackAlert = useSnackAlert();

  const stompClient = useMemo(() => {
    return new Client();
  }, []);

  const updateMatch = (match: MatchDTO) => setMatches((prevState) => {
    const updatedMatchList: Array<MatchDTO> = [];
    prevState.forEach(item => {
      const isItemBeingUpdated = item.id === match.id;
      updatedMatchList.push(isItemBeingUpdated ? match : item);
    });
    return updatedMatchList;
  });

  const generateMatchSubscriptionCallback = useCallback((matchId: number) => async ({body}: IMessage) => {
    const newState = JSON.parse(body) as MatchStateResponse;
    if (newState.matchStatus === 'COMPLETE') {
      const match = await tcsApi.matches.getById(matchId);
      setMatchSubscriptions((prevState) => {
        const matchSubscription = prevState.find((subscription) => subscription.matchId === matchId);
        matchSubscription?.subscription.unsubscribe();
        return prevState.filter((subscription) => subscription.matchId !== matchId);
      });
      setMatches(prevState => prevState.filter(match => match.id !== matchId));
      setCompletedMatches(prevState => [...prevState, match]);
      const score = await getScoreForMatch(match);
      const entrantDetailsWithScore = score.scores.map((score) => ({
        ... entrantDetailsMap.get(score.entrantId),
        score: score.score
      }));
      const winnerInfo = entrantDetailsWithScore[0].score > entrantDetailsWithScore[1].score ? entrantDetailsWithScore[0] : entrantDetailsWithScore[1];
      const loserInfo = entrantDetailsWithScore[0].score > entrantDetailsWithScore[1].score ? entrantDetailsWithScore[1] : entrantDetailsWithScore[0];
      openSnackAlert({
        duration: 30000,
        message: {
          body: <Typography>
            <span style={{color: 'lightgreen'}}>{winnerInfo.name}</span> defeated <span style={{color: 'darkred'}}>{loserInfo.name}</span> {winnerInfo.score}-{loserInfo.score} in {match.roundText}.
          </Typography>
        },
        position: 'bottom-left'
      });
      setCompletedMatchScores(prevState => [...prevState, score]);
    }

    setInProgressMatchStates((prevState) => ([
      ...prevState.filter(matchState => matchState.matchId !== matchId),
      {...newState, matchId}
    ]));
  }, [entrantDetailsMap]);

  const refreshCompletedMatches = useCallback(async () => {
    const completedMatchesFromServer = await tcsApi.matches.getTournamentMatches(tournamentId, true);
    setCompletedMatches(completedMatchesFromServer);
    completedMatchesFromServer.forEach(match => {
      getScoreForMatch(match).then(score => setCompletedMatchScores(prevState => [...prevState, score]));
    });
  }, [tournamentId]);

  const moveMatchToComplete = useCallback(async (matchId: number) => {
    const match = await tcsApi.matches.getById(matchId);
    setCompletedMatches((prevState) => [...prevState, match]);
    const score = await getScoreForMatch(match);
    setCompletedMatchScores(prevState => [...prevState, score]);
  }, []);

  useEffect(() => {
    if (stompClient) {
      const effect = async () => {
        const topicSubscriptionPath = `/topic/tournament/${tournamentId}`;
        stompClient.configure({
          brokerURL: env.api.matchEngineStompEndpoint,
          onConnect: () => {
            if (stompClient.connected) {
              stompClient.subscribe(topicSubscriptionPath, ({body}) => {
                const message = JSON.parse(body) as TournamentSubscriptionMessage;
                console.log('received message', message);
                switch (message.type) {
                case 'checkins':
                  setEntrantCheckedInStatus(message.data.id, true);
                  break;
                case 'matches':
                  setMatches(message.data);
                  if (message.data.length == 0) {
                    console.log('No matches, refreshing tournaments');
                    void refreshTournaments();
                  }
                }
              });

              // Regenerating subscriptions for each match (necessary if this is a re-connect)
              setMatchSubscriptions((prevState) => prevState.map((subscription) => ({
                matchId: subscription.matchId,
                subscription: stompClient.subscribe(`/topic/tournament/${tournamentId}/match/${subscription.matchId}`, generateMatchSubscriptionCallback(subscription.matchId))
              })));
            }
          },
          beforeConnect: async () => {
            stompClient.connectHeaders = {
              'auth-token': `${await auth.currentUser?.getIdToken()}`,
              'Match-Connection-Type': 'Observer'
            };
          },
          forceBinaryWSFrames: true,
          appendMissingNULLonIncoming: true
        });

        const activateStompClient = (retryCount = 0) => {
          try {
            stompClient.activate();
          } catch (err) {
            if (retryCount < 5) {
              // Wait a second and retry
              setTimeout(() => {
                activateStompClient(retryCount + 1);
              }, 1000);
            } else {
              console.error('Failed to activate stomp client after 5 attempts', err);
            }
          }
        };

        activateStompClient();
      };

      void effect();

      return () => {
        stompClient.deactivate().then(undefined);
      };
    }

  }, [generateMatchSubscriptionCallback, stompClient, tournamentId]);

  useEffect(() => {
    const effect = async () => {
      const incompleteMatches: Array<MatchDTO> = [];
      const completeMatches: Array<MatchDTO> = [];
      const tournamentMatches = await tcsApi.matches.getTournamentMatches(tournamentId);
      tournamentMatches.forEach(match => {
        if (match.status === 'COMPLETE') {
          completeMatches.push(match);
        } else {
          incompleteMatches.push(match);
        }
      });
      setCompletedMatches(completeMatches);
      const { gameMap } = await tcsApi.games.getBulkGames({
        matchIds: completeMatches.map(match => match.id)
      });
      completeMatches.forEach(match => {
        getScoreForMatch(match, gameMap[match.id]).then(score => setCompletedMatchScores(prevState => [...prevState, score]));
      });
      setMatches(incompleteMatches);
    };
    effect();
  }, [tournamentId]);

  useEffect(() => {
    if (stompClient?.connected) {
      const currentInProgressMatchIds = matches.filter((match) => ['IN_PROGRESS', 'CALLED'].includes(match.status)).map(match => match.id);
      const currentSubscribedMatchIds = matchSubscriptions.map(subscription => subscription.matchId);

      const subscriptionsToAdd = currentInProgressMatchIds.filter(matchId => !currentSubscribedMatchIds.includes(matchId));

      if (subscriptionsToAdd.length > 0) {
        const newSubscriptions: Array<MatchSubscription> = subscriptionsToAdd.map((matchId) => {
          const topicSubscriptionPath = `/topic/tournament/${tournamentId}/match/${matchId}`;
          const subscription = stompClient.subscribe(topicSubscriptionPath, generateMatchSubscriptionCallback(matchId));
          return {matchId, subscription};
        });

        setMatchSubscriptions((prevState) => ([
          ...prevState,
          ...newSubscriptions
        ]));

        setInProgressMatchStates((prevState) => prevState.filter(matchState => currentInProgressMatchIds.includes(matchState.matchId)));
      }
    }

  }, [generateMatchSubscriptionCallback, matchSubscriptions, matches, stompClient, tournamentId]);

  const uncalledMatches = useMemo(() => matches.filter(match => match.status === 'NOT_STARTED'), [matches]);
  const inProgressMatches = useMemo(() => matches.filter(match => ['IN_PROGRESS', 'CALLED'].includes(match.status)), [matches]);

  const stationsInUse = useMemo(() => inProgressMatches.map(match => match.assignedStation).filter(station => station !== ''), [inProgressMatches]);
  const stationsReadyToCall = useMemo(() => uncalledMatches
    .map(match => match.assignedStation)
    .filter(station => station !== '')
    .filter(station => !stationsInUse.includes(station)),
  [uncalledMatches, stationsInUse]);
  const idleStations = useMemo(() => storedStationGroup?.stationNames.filter(station => !stationsInUse.includes(station)) || [], [stationsInUse, storedStationGroup]);

  if (loading || entrantsLoading || !stompClient.connected) {
    return <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress/>
    </div>;
  }

  return <React.Fragment>
    <ManageRulesetModal
      handleClose={() => setManageRulesetModalOpen(false)}
      open={manageRulesetModalOpen}
      loading={loading} tournamentId={tournamentId}
      storedRuleset={storedRuleset}
      updateStoredRuleset={updateStoredRuleset}
      readOnly
    />
    <ManageStationsModal handleClose={() => setManageStationGroupModalOpen(false)}
      open={manageStationGroupModalOpen} tournamentId={tournamentId}
      storedStationGroup={storedStationGroup}
      updateStoredStationGroup={updateStoredStationGroup}/>
    <ConfirmResetBracketModal
      handleClose={() => setResetBracketModalOpen(false)}
      open={resetBracketModalOpen}
      tournamentId={tournamentId}
    />
    <Grid container direction="row" justifyContent='center' marginBottom={3}>
      <Grid item xs={4} sx={{textAlign: 'center'}}>
        <Button onClick={() => setManageRulesetModalOpen(true)}>View Ruleset</Button>
      </Grid>
      <Grid item xs={4} sx={{textAlign: 'center'}}>
        <Button onClick={() => setManageStationGroupModalOpen(true)}>Manage Stations</Button>
      </Grid>
      <Grid item xs={4} sx={{textAlign: 'center'}}>
        <Button color='error' onClick={() => setResetBracketModalOpen(true)}>Reset Bracket</Button>
      </Grid>
    </Grid>
    <Grid container spacing={3} direction="row" justifyContent='center'>
      <MatchGroupHeaderCard
        amountMatches={uncalledMatches.length}
        checked={showUncalledChecked}
        groupConfigs={groupConfigs.uncalledGroupConfigSet}
        toggle={toggleShowUncalled}
      />
      <MatchGroupHeaderCard
        amountMatches={inProgressMatches.length}
        checked={showInProgressChecked}
        groupConfigs={groupConfigs.inProgressGroupConfigSet}
        toggle={toggleShowInProgress}
      />
      <MatchGroupHeaderCard
        amountMatches={completedMatches.length}
        checked={showCompletedChecked}
        groupConfigs={groupConfigs.completedGroupConfigSet}
        toggle={toggleShowCompleted}
      />
    </Grid>
    <StationAvailability availableStations={idleStations} stationsInUse={stationsInUse} stationsReadyToCall={stationsReadyToCall} />
    {
      showUncalledChecked && <UncalledMatches
        matches={uncalledMatches}
        updateMatch={updateMatch}
        storedStationGroup={storedStationGroup}
        stationsInUse={stationsInUse}
        showIncompleteChecked={showIncompleteChecked}
        toggleIncompleteChecked={toggleShowIncomplete}
        handleMatchComplete={moveMatchToComplete}
        ruleset={storedRuleset!}
      />
    }
    {
      showInProgressChecked && <InProgressMatches
        matches={inProgressMatches}
        matchStates={inProgressMatchStates}
        updateMatch={updateMatch}
        storedStationGroup={storedStationGroup}
        stationsInUse={stationsInUse}
        handleRefreshCompletedMatches={refreshCompletedMatches}
        handleMatchComplete={moveMatchToComplete}
        ruleset={storedRuleset!}
      />
    }
    {
      showCompletedChecked && <CompletedMatches
        matches={completedMatches}
        scores={completedMatchScores}
        handleRefreshCompletedMatches={refreshCompletedMatches}
      />
    }
  </React.Fragment>;
};

export default InProgressTournament;
