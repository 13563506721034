import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle, FormControl, FormControlLabel,
  LinearProgress, Radio, RadioGroup, Typography
} from '@mui/material';
import tcsApi from '../api/tc-server/api';
import { EntrantDetailsDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import { useSnackAlert } from '../context/SnackAlertContext';

interface Props {
    handleClose: () => void;
    open: boolean;
    matchId: number;
    entrants: EntrantDetailsDTO[];
    handleMatchComplete: (matchId: number) => Promise<void>;
}

const ReportDisqualificationModal = (props:Props):JSX.Element => {
  const [reportInProgress, setReportInProgress] = useState(false);
  const [disqualifiedEntrantId, setDisqualifiedEntrantId] = useState<number>();
  const { entrants, handleClose, open, matchId, handleMatchComplete } = props;

  const openSnackAlert = useSnackAlert();

  const closeModal = () => {
    handleClose();
    setDisqualifiedEntrantId(undefined);
  };

  const onEntrantSelected = (event: React.ChangeEvent<HTMLInputElement>) => setDisqualifiedEntrantId(Number.parseInt(event.target.value));

  const reportDisqualification = async () => {
    try {
      setReportInProgress(true);
      await tcsApi.matches.reportDisqualification(matchId, {
        winningEntrantId: disqualifiedEntrantId === entrants[0].id ? entrants[1].id : entrants[0].id
      });
      await handleMatchComplete(matchId);
    } catch (err) {
      console.error('failed to report disqualification', err);
      openSnackAlert({
        message: { text: 'Something went wrong reporting this DQ. Please refresh the page and try again.' },
        severity: 'error'
      });
    }
    setReportInProgress(false);
  };

  return <Dialog
    maxWidth={'sm'}
    open={open}
    onClose={reportInProgress ? undefined : closeModal}
  >
    <DialogTitle>Report Disqualification</DialogTitle>
    <FormControl sx={{ paddingX: 3 }}>
      <Typography>{'Which player is being disqualified from this match?'}</Typography>
      <RadioGroup
        row
        aria-labelledby={'report-dq-radio-group-label'}
        name={'report-dq-radio-group'}
        value={disqualifiedEntrantId ?? ''}
        onChange={(event) => onEntrantSelected(event)}
      >
        <FormControlLabel value={entrants[0].id} control={<Radio />} label={entrants[0].name} />
        <FormControlLabel value={entrants[1].id} control={<Radio />} label={entrants[1].name} />
      </RadioGroup>
    </FormControl>
    <DialogActions>
      <Button disabled={reportInProgress} onClick={closeModal}>Cancel</Button>
      <Button disabled={reportInProgress || !disqualifiedEntrantId} onClick={reportDisqualification}>Report DQ</Button>
    </DialogActions>
    {reportInProgress && <LinearProgress />}
  </Dialog>;
};

export default ReportDisqualificationModal;
