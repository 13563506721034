import React, { useMemo, useState } from 'react';
import { Box, Button, Grid, LinearProgress, Switch, Typography } from '@mui/material';
import UncalledMatchCard from './UncalledMatchCard';
import { MatchDTO, RulesetDTO, StationGroupDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import MatchGroup, {groupMatchesByRound} from './MatchGroup';
import { groupConfigs } from './InProgressTournament';
import tcsApi from '../api/tc-server/api';

interface Props {
    matches: Array<MatchDTO>;
    storedStationGroup?: StationGroupDTO;
    stationsInUse: Array<string>;
    updateMatch: (val: MatchDTO) => void;
    showIncompleteChecked: boolean;
    toggleIncompleteChecked: () => void;
    handleMatchComplete: (matchId: number) => Promise<void>;
    ruleset: RulesetDTO;
}

const UncalledMatches = (props: Props): JSX.Element => {
  const {
    matches,
    storedStationGroup,
    showIncompleteChecked,
    stationsInUse,
    toggleIncompleteChecked,
    updateMatch,
    handleMatchComplete,
    ruleset
  } = props;

  const filteredMatches = useMemo(() => showIncompleteChecked ? matches : matches.filter((match) => match.entrantIds.length === 2), [matches, showIncompleteChecked]);
  const matchGroups = useMemo(() => groupMatchesByRound(filteredMatches), [filteredMatches]);
  const [bulkCallInProgress, setBulkCallInProgress] = useState(false);

  const callableMatchIds = useMemo(() => {
    const readyMatches = matches
      .filter((match) => match.entrantIds.length === 2)
      .filter((match) => Boolean(match.assignedStation))
      .filter((match) => !stationsInUse.includes(match.assignedStation));

    const seenStations = new Set<string>();
    const readyMatchesWithUniqueStations = readyMatches.filter((match) => {
      const duplicate = seenStations.has(match.assignedStation);
      seenStations.add(match.assignedStation);
      return !duplicate;
    });

    return readyMatchesWithUniqueStations.map((match) => match.id);
  }, [matches, stationsInUse]);

  const callMatches = async () => {
    setBulkCallInProgress(true);
    try {
      const updatedMatches = await tcsApi.matches.call({ matchIds: callableMatchIds });
      updatedMatches.forEach((match) => updateMatch(match));
    } catch (err) {
      console.error('failed to update matches', err);
    }
    setBulkCallInProgress(false);
  };

  return <React.Fragment>
    <Grid container direction='row' alignItems='flex-end' justifyContent='space-between'>
      <Grid item>
        <Typography
          sx={{ marginBottom: 2 }} variant="h5">
                    Uncalled Matches
        </Typography>
      </Grid>
      <Grid item>
                Include matches with incomplete entrants?<Switch onClick={toggleIncompleteChecked} checked={showIncompleteChecked}/>
      </Grid>
    </Grid>
    { callableMatchIds.length > 0 && <Button
      disabled={bulkCallInProgress}
      onClick={callMatches}
      color={groupConfigs.uncalledGroupConfigSet.checkBoxColor}
    >
            Call Ready Matches to Assigned Stations ({ callableMatchIds.length } Matches)
    </Button>
    }
    {bulkCallInProgress && <LinearProgress color={groupConfigs.uncalledGroupConfigSet.checkBoxColor} sx={{ marginX: 2, marginBottom: 1 }}/>}
    <Box padding={2} sx={{ border: 1, borderStyle: 'dashed', borderColor: groupConfigs.uncalledGroupConfigSet.borderColor }}>
      {
        filteredMatches.length === 0
          ? <Typography textAlign='center'>There are no uncalled matches.</Typography>
          : matchGroups.map((group, index) => <MatchGroup
            key={group.round}
            noDivider={index === (matchGroups.length - 1)}
            isBo5={group.isBo5}
            round={group.round}
            roundName={group.roundName}
            matchCards={group.matches.map((match) => <Grid key={match.id} item>
              <UncalledMatchCard
                entrantIds={match.entrantIds}
                match={match}
                stations={storedStationGroup?.stationNames ?? []}
                stationsInUse={stationsInUse}
                updateMatch={updateMatch}
                handleMatchComplete={handleMatchComplete}
                ruleset={ruleset}
              />
            </Grid>)}
          />)
      }
    </Box>
  </React.Fragment>;
};

export default UncalledMatches;