import React, { useReducer, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle, FormControl, FormControlLabel, FormLabel,
  LinearProgress, Radio, RadioGroup, Stack,
} from '@mui/material';
import tcsApi from '../api/tc-server/api';
import { EntrantDetailsDTO, RulesetDTO } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import { useSnackAlert } from '../context/SnackAlertContext';
import CharacterSelector from '../components/CharacterSelector';
import { SSBUStage } from '../types/ssbu/ssbu_stages';
import { SSBUCharacter } from '../types/ssbu/ssbu_characters';
import StageSelector from '../components/StageSelector';

interface Props {
    handleClose: () => void;
    open: boolean;
    matchId: number;
    isBo5: boolean;
    entrants: EntrantDetailsDTO[];
    handleMatchComplete: (matchId: number) => Promise<void>;
    ruleset: RulesetDTO;
}

interface GameResult {
  winnerId?: number;
  stage: SSBUStage;
  characterSelections: Array<{
    entrantId: number;
    character?: SSBUCharacter;
  }>;
}

interface ReportState {
    isBo5: boolean;
    entrants: EntrantDetailsDTO[];
    gameResults: GameResult[];
    gameSlots: number;
}

type Action =
  | { type: 'reset_state' }
  | { type: 'select_game_winner', gameNumber: number, winnerId: number }
  | { type: 'select_character', gameNumber: number, entrantId: number, character?: SSBUCharacter }
  | { type: 'select_stage', gameNumber: number, stage?: SSBUStage };

const EMPTY_GAME_RESULT: GameResult = {
  stage: 'DEFAULT',
  characterSelections: []
};

const generateCleanGameSlate = (isBo5: boolean) => isBo5
  ? [{ ...EMPTY_GAME_RESULT }, { ...EMPTY_GAME_RESULT }, { ...EMPTY_GAME_RESULT }]
  :  [{ ...EMPTY_GAME_RESULT }, { ...EMPTY_GAME_RESULT }];

const reportStateReducer = (state: ReportState, action: Action): ReportState => {
  switch (action.type) {
  case 'reset_state':
    return {
      isBo5: state.isBo5,
      entrants: state.entrants,
      gameResults: generateCleanGameSlate(state.isBo5),
      gameSlots: 2
    };
  case 'select_game_winner': {
    const newGameResults = [...state.gameResults];
    const currentGameIndex = action.gameNumber - 1;

    // Update the winner for the selected game
    newGameResults[currentGameIndex] = {
      ...newGameResults[currentGameIndex],
      winnerId: action.winnerId,
    };

    const gamesNeededToWin = state.isBo5 ? 3 : 2;
    const newWinnerIds = newGameResults.map(result => result?.winnerId).filter(Boolean) as number[];
    const player1GamesWon = newWinnerIds.filter(id => id === state.entrants[0].id).length;
    const player2GamesWon = newWinnerIds.filter(id => id === state.entrants[1].id).length;

    let slotQuantity = 0;
    if (player1GamesWon >= gamesNeededToWin || player2GamesWon >= gamesNeededToWin) {
      const countMap = new Map<number, number>();
      for (let i = 0; i < newWinnerIds.length; i++) {
        const value = newWinnerIds[i];
        const count = countMap.get(value) || 0;
        countMap.set(value, count + 1);
        if (countMap.get(value) === gamesNeededToWin) {
          slotQuantity = i + 1;
          break;
        }
      }
    } else {
      slotQuantity = Math.max(newWinnerIds.length + 1, gamesNeededToWin);
    }

    // Initialize new games if needed
    while (newGameResults.length < slotQuantity) {
      const previousGame = newGameResults[newGameResults.length - 1];
      newGameResults.push({
        stage: 'DEFAULT',
        characterSelections: previousGame.characterSelections ? [...previousGame.characterSelections] : []
      });
    }

    return {
      ...state,
      gameResults: newGameResults.slice(0, slotQuantity),
      gameSlots: slotQuantity,
    };
  }
  case 'select_character': {
    // Update targeted game character selection
    const gameResult = state.gameResults[action.gameNumber - 1];
    const characterSelections = gameResult.characterSelections.filter(selection => selection.entrantId !== action.entrantId);
    const newCharacterSelections = [...characterSelections, { entrantId: action.entrantId, character: action.character }];

    // Add a character selection for all other games for this entrant that don't already have one
    const updatedGameResults = state.gameResults.map((gameResult, index) => {
      // Check if the current game is the one being targeted by the action
      if (index === action.gameNumber - 1) {
        return { ...gameResult, characterSelections: newCharacterSelections };
      }

      // Check if this game already has a character selection for this entrant
      const hasSelection = gameResult.characterSelections.some(selection => selection.entrantId === action.entrantId);

      // If it does, return the gameResult as is, otherwise add the character selection
      if (!hasSelection) {
        return {
          ...gameResult,
          characterSelections: [...gameResult.characterSelections, { entrantId: action.entrantId, character: action.character }]
        };
      }

      return gameResult;
    });

    return {
      ...state,
      gameResults: updatedGameResults
    };
  }
  case 'select_stage':
    return {
      ...state,
      gameResults: state.gameResults.map((gameResult, index) => index === action.gameNumber - 1 ? { ...gameResult, stage: action.stage ?? 'DEFAULT' } : gameResult)
    };
  default:
    return state;
  }
};

const SetScoreModal = (props:Props):JSX.Element => {
  const { entrants, handleClose, open, matchId, isBo5, handleMatchComplete, ruleset } = props;
  const [reportInProgress, setReportInProgress] = useState(false);
  const [reportState, dispatch] = useReducer(reportStateReducer, {
    isBo5,
    entrants,
    gameResults: generateCleanGameSlate(isBo5),
    gameSlots: isBo5 ? 3 : 2
  });

  const openSnackAlert = useSnackAlert();

  const { gameResults, gameSlots } = reportState;

  const closeModal = () => {
    handleClose();
    dispatch({ type: 'reset_state' });
  };

  const onGameWinnerSelected = (gameNumber: number, event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'select_game_winner',
      gameNumber,
      winnerId: Number.parseInt(event.target.value),
    });
  };

  const reportScore = async () => {
    try {
      setReportInProgress(true);
      await tcsApi.matches.reportResult(matchId, {
        gameReports: gameResults.map((result, index) => ({
          stage: result.stage ?? 'DEFAULT',
          gameNumber: index + 1,
          winningEntrantId: result.winnerId!,
          characterSelections: result.characterSelections
        }))
      });
      await handleMatchComplete(matchId);
    } catch (err) {
      console.error('failed to report match score', err);
      openSnackAlert({
        message: { text: 'Something went wrong reporting your match. Please refresh the page and try again.' },
        severity: 'error'
      });
    }
    setReportInProgress(false);
  };

  const getCharacterByGameIdAndEntrantId = (gameNumber: number, entrantId: number): SSBUCharacter | undefined => {
    const gameResult = gameResults[gameNumber - 1];
    if (gameResult) {
      const characterSelection = gameResult.characterSelections?.find(selection => selection.entrantId === entrantId);
      return characterSelection?.character;
    }
  };

  const reportEnabled = gameSlots === gameResults.filter(result => result.winnerId).length;

  const renderGameSection = (gameNumber: number): JSX.Element => {
    const labelId = `game-${gameNumber}-radio-group-label`;
    return (
      <FormControl sx={{ marginBottom: 2 }} key={`group-${gameNumber}`}>
        <FormLabel id={labelId}>{`Select winner for game ${gameNumber}`}</FormLabel>
        <RadioGroup
          row
          aria-labelledby={labelId}
          name={`game-${gameNumber}-radio-group`}
          value={gameResults[gameNumber - 1]?.winnerId ?? ''}
          onChange={(event) => onGameWinnerSelected(gameNumber, event)}
          sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
        >
          <FormControlLabel
            value={entrants[0].id}
            control={<Radio />}
            label={
              <Stack direction="row" alignItems="center">
                <CharacterSelector
                  selectedCharacter={getCharacterByGameIdAndEntrantId(gameNumber, entrants[0].id)}
                  onCharacterSelect={(character?: SSBUCharacter) => dispatch({ type: 'select_character', gameNumber, entrantId: entrants[0].id, character })}
                />
                {entrants[0].name}
              </Stack>
            }
            sx={{ flexDirection: 'row-reverse', mr: 2 }}
          />
          <Button
            onClick={undefined}
          >
            <StageSelector
              selectedStage={gameResults[gameNumber - 1].stage}
              onStageSelect={(stage) => dispatch({ type: 'select_stage', gameNumber, stage })}
              selectableStages={gameNumber === 1 ? ruleset.starterStages : [...ruleset.starterStages, ...ruleset.counterpickStages]}
            />
          </Button>
          <FormControlLabel
            value={entrants[1].id}
            control={<Radio />}
            label={
              <Stack direction="row" alignItems="center">
                {entrants[1].name}
                <CharacterSelector
                  selectedCharacter={getCharacterByGameIdAndEntrantId(gameNumber, entrants[1].id)}
                  onCharacterSelect={(character?: SSBUCharacter) => dispatch({ type: 'select_character', gameNumber, entrantId: entrants[1].id, character })}
                />
              </Stack>
            }
            sx={{ ml: 2 }}
          />
        </RadioGroup>
      </FormControl>
    );
  };

  return <Dialog maxWidth={'md'} open={open} onClose={reportInProgress ? undefined : closeModal}>
    <DialogTitle>Report match results</DialogTitle>
    <Stack alignItems='center' p={3}>
      {[...Array(gameSlots)].map((_, i) => renderGameSection(i+1))}
    </Stack>
    <DialogActions>
      <Button disabled={reportInProgress} onClick={closeModal}>Cancel</Button>
      <Button disabled={reportInProgress || !reportEnabled} onClick={reportScore}>Report Match Result</Button>
    </DialogActions>
    {reportInProgress && <LinearProgress />}
  </Dialog>;
};

export default SetScoreModal;
