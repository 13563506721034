import React, { useContext, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from '@mui/material';
import tcsApi from '../api/tc-server/api';
import { ManagedTournamentsContext } from '../context/ManagedTournamentsContext';

interface Props {
    handleClose: () => void
    open: boolean,
    tournamentId: number,
}

const ConfirmResetBracketModal = (props:Props):JSX.Element => {
  const [resetInProgress, setResetInProgress] = useState(false);
  const { refreshTournaments } = useContext(ManagedTournamentsContext);
  const [error, setError] = useState(false);

  const { handleClose, open, tournamentId } = props;

  const closeModal = () => {
    handleClose();
    setError(false);
  };

  const resetBracket = async () => {
    try {
      setError(false);
      setResetInProgress(true);
      await tcsApi.tournaments.resetBracket(tournamentId);
      await refreshTournaments();
      setError(true);
    } catch (err) {
      setError(true);
      console.error('failed to reset bracket', err);
    }
    setResetInProgress(false);
  };

  return <Dialog maxWidth={'sm'} open={open} onClose={resetInProgress ? undefined : closeModal}>
    <DialogTitle>Reset this bracket?</DialogTitle>
    {error && <Alert variant='filled' severity="error">Failed to reset bracket.</Alert>}
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
                This will completely reset the progress of the tournament. The results of all matches will be reverted
                and all entrants will have to check in again. <b>All match data will be lost. This is irreversible.</b>
        <br/>
        <br/>
        Note: This may take a while! Startgg can take up to two minutes to process the reset.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button disabled={resetInProgress} onClick={closeModal}>Cancel</Button>
      <Button disabled={resetInProgress} onClick={resetBracket}>Reset Bracket</Button>
    </DialogActions>
    {resetInProgress && <LinearProgress />}
  </Dialog>;
};

export default ConfirmResetBracketModal;
