import React, { useState, MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SSBUStage, ssbuStages } from '../types/ssbu/ssbu_stages';
import { Add } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';

interface Props {
  selectedStage?: SSBUStage;
  onStageSelect: (stage?: SSBUStage) => void;
  selectableStages: SSBUStage[];
}

const StageSelector = (props: Props) => {
  const { selectedStage, onStageSelect, selectableStages } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).closest('.clear-button')) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStageSelect = (stage: SSBUStage) => {
    onStageSelect(stage);
    handleClose();
  };

  const handleClearSelection = () => {
    onStageSelect(undefined);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectedStage && selectedStage !== 'DEFAULT' ? (
            <>
              <img src={ssbuStages[selectedStage].image} alt={ssbuStages[selectedStage].name} style={{ borderRadius: 5, width: 70, height: 39 }} />
            </>
          ) : (
            <Box
              sx={{ borderRadius: 2, display: 'flex', flexDirection: 'column', color: 'primary.main', border: 2, width: 70, height: 39, textDecoration: 'none' }}
              justifyContent="center"
              alignItems="center"
            >
              <Add sx={{ textAlign: 'center', font: 'inherit', fontSize: 12 }} />
              <Typography fontSize={10}>Stage</Typography>
            </Box>
          )}
        </div>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {selectedStage && selectedStage !== 'DEFAULT' && (
          <MenuItem onClick={handleClearSelection}>
            <Typography>Clear Selection</Typography>
          </MenuItem>
        )}
        <Grid container spacing={1} justifyContent="space-between" padding={1}>
          {selectableStages.map((stage) => (
            <Grid item xs={4} key={stage} style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                onClick={() => handleStageSelect(stage)}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80px' }}
              >
                <img src={ssbuStages[stage].image} alt={ssbuStages[stage].name} style={{ width: 70, height: 39, borderRadius: 5 }} />
                <Typography variant="caption" align="center">
                  {ssbuStages[stage].name}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </div>
  );
};

export default StageSelector;