import React from 'react';
import { Chip, Grid, Link, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {TournamentDTO} from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import tcLogo from '../assets/tc_logo_no_text_margins.png';

interface Props {
  tournament:TournamentDTO
}

const renderChipFromStatus = (statusType:string):JSX.Element => {
  switch (statusType){
  case 'NOT_STARTED':
    return <Chip label='Not Started'/>;
  case 'IN_PROGRESS':
    return <Chip color='secondary' label='Live!' icon={<CircleIcon style={{ color: 'red'}} />}/>;
  case 'COMPLETE':
    return <Chip color='primary' label='Complete'/>;
  default:
    return <></>;
  }
};

const TournamentHeader = (props:Props):JSX.Element => {
  const { tournament } = props;
  return <Grid spacing={4} marginTop={0} marginBottom={4} container direction='row' justifyContent='center' alignItems='center'>
    <Grid item>
      <img style={{ height: 100, width: 100, borderRadius: 10, borderStyle: 'solid', borderWidth: 2, borderColor: 'white' }} src={tournament.imageUrl || tcLogo} alt={tournament.name}/>
    </Grid>
    <Grid item>
      <Grid
        container
        spacing={2}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='h3'>{tournament.name}</Typography>
        <Grid container direction='row' justifyContent='space-around'>
          {renderChipFromStatus(tournament.status)}
          <Link href={`https://start.gg/${tournament.slug}`} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>View Event on Startgg</Link>
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
};

export default TournamentHeader;