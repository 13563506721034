import {Button, Fade, Grid, Paper, Typography} from '@mui/material';
import React, {useRef, useState} from 'react';
import SelectStagesModal from '../modals/SelectStagesModal';
import {SSBUStage} from '../types/ssbu/ssbu_stages';
import EditIcon from '@mui/icons-material/Edit';
import StageTab from './StageTab';
import {useHover} from 'usehooks-ts';

interface Props {
    groupName: string,
    maxAllowed?: number,
    minAllowed?: number,
    stages: Array<SSBUStage>,
    handleUpdateStages: (stages:Array<SSBUStage>) => void,
    unavailableStages?: Array<SSBUStage>,
    readOnly?: boolean
}

const RulesetStageGroup = (props:Props):JSX.Element => {
  const { groupName, maxAllowed, minAllowed, handleUpdateStages, stages, unavailableStages, readOnly = false } = props;
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);

  const [editing, setEditing] = useState(false);

  const closeModal = () => setEditing(false);
  const openModal = () => {
    if (!readOnly) setEditing(true);
  };

  const stagesSet = stages.length > 0;


  return <Paper sx={{ margin: 1, paddingY: 3, paddingX: 1}} elevation={6}>
    <SelectStagesModal
      groupName={groupName}
      maxAllowed={maxAllowed}
      minAllowed={minAllowed}
      open={editing} handleClose={closeModal}
      currentStages={stages}
      handleAccept={handleUpdateStages}
      unavailableStages={unavailableStages}
    />
    <Paper onClick={openModal} elevation={2} ref={hoverRef} sx={{cursor: readOnly ? 'auto' : 'pointer', marginX: 2, padding: 1, paddingTop: 3}}>
      <Typography marginLeft={2} marginBottom={1}><b>{groupName}:</b></Typography>
      {
        stagesSet
          ? <Grid paddingX={2} spacing={2} container alignItems='stretch' justifyContent='space-around'>
            {stages.map(stage => <StageTab key={stage} showToggle={false} stage={stage}/>)}
          </Grid>
          : <div style={{ display: 'flex', justifyContent: 'center'}}>
            <Button onClick={openModal}>Set Stages</Button>
          </div>
      }
      {
        <Fade in={isHovered && stagesSet && !readOnly}>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button disabled={!stagesSet}><EditIcon color='primary' fontSize='large'/></Button>
          </div>
        </Fade>
      }
    </Paper>
  </Paper>;
};

export default RulesetStageGroup;