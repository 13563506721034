import React from 'react';
import {Grid, Paper, TextField} from '@mui/material';
import HighlightOff from '@mui/icons-material/HighlightOff';

interface Props {
    index: number,
    stationName:string,
    onUpdate: (index:number, val:string) => void,
    onDelete: () => void
}

const StationCard = (props:Props):JSX.Element => {
  const { index, stationName, onUpdate, onDelete } = props;

  return <Grid item sm={6} md={4} lg={3}>
    <Paper sx={{ padding: 1}}>
      <Grid direction='column' container alignItems='center' justifyContent='center'>
        <Grid item sx={{ width: '100%' }} textAlign='right'>
          <HighlightOff onClick={onDelete} sx ={{ cursor: 'pointer', alignSelf: 'end'}} fontSize='medium'/>
        </Grid>
        <Grid item>
          <TextField
            label='Station Name'
            value={stationName}
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            onChange={(event) => onUpdate(index, event.target.value)}
          />
        </Grid>
      </Grid>
    </Paper>
  </Grid>;
};

export default StationCard;
