import React, {useContext, useState} from 'react';
import {Button, Container, Dialog, DialogTitle, LinearProgress, Stack, TextField, Typography} from '@mui/material';
import { EventLookupResponse } from '@tourneycompanion/tcs-js-sdk/dist/types/types/types';
import {useNavigate} from 'react-router-dom';
import tcsApi from '../api/tc-server/api';
import {AuthContext} from '../context/AuthContext';
import {ManagedTournamentsContext} from '../context/ManagedTournamentsContext';
import {AxiosError, isAxiosError} from 'axios';

const startggEventUrlPattern = /((https:\/\/)|(http:\/\/))?(www\.)?start.gg\/tournament\/.+\/events?\/.+/;
const startggTournamentUrlPattern = /((https:\/\/)|(http:\/\/))?(www\.)?start.gg\/tournament\/.+/;
const slugPattern = /tournament\/.*\/events?\/.*?(\/|$)/;

interface LoadingState {
    lookupInProgress: boolean,
    saveInProgress: boolean
}

interface Props {
    open: boolean,
    handleClose: () => void
}

const getSlugFromUrl = (url:string):string => {
  const eventSlug = (slugPattern.exec(url) || [])[0];
  return (eventSlug ?? '').replace(/\/+$/, '');
};

const AddTournamentModal = (props:Props):JSX.Element => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { refreshTournaments } = useContext(ManagedTournamentsContext);
  const { handleClose, open } = props;

  const [externalUrl, setExternalUrl] = useState('');
  const [loadingState, setLoadingState] = useState<LoadingState>({
    lookupInProgress: false,
    saveInProgress: false
  });
  const [lookupErrorText, setLookupErrorText] = useState('');
  const [createErrorText, setCreateErrorText] = useState('');
  const [readyToLookup, setReadyToLookup] = useState(false);
  const [event, setEvent] = useState<EventLookupResponse>();
  const [tcTourneyName, setTcTourneyName] = useState('');

  const onClose = () => {
    handleClose();
    setExternalUrl('');
    setLoadingState({ saveInProgress: false, lookupInProgress: false });
    setLookupErrorText('');
    setCreateErrorText('');
    setReadyToLookup(false);
    setEvent(undefined);
    setTcTourneyName('');
  };

  const updateExternalUrlValue = (value:string) => {
    setExternalUrl(value);
    setCreateErrorText('');
    if (startggEventUrlPattern.test(value)) {
      setReadyToLookup(true);
      setLookupErrorText('');
    } else {
      setReadyToLookup(false);
      if (!value) {
        setLookupErrorText('');
      } else if (startggTournamentUrlPattern.test(value)) {
        setLookupErrorText('Looks like you may have provided a Startgg Tournament URL, we\'re looking for the URL of a particular event within that tournament.');
      } else {
        setLookupErrorText('This doesn\'t appear to be a Startgg Event URL. Please verify that this is a valid link!');
      }
    }
  };

  const lookupTournament = async () => {
    setLoadingState((prevState) => ({ ...prevState, lookupInProgress: true }));
    const slug = getSlugFromUrl(externalUrl);
    try {
      const externalEvent = await tcsApi.tournaments.lookup('STARTGG', slug.replace('/events/', '/event/'));
      setTcTourneyName(externalEvent.name);
      setEvent(externalEvent);
    } catch (err) {
      setLookupErrorText('Unable to locate that event. If this is a valid event URL, you may not have admin privileges for it.');
    }
    setLoadingState((prevState) => ({ ...prevState, lookupInProgress: false }));
  };

  const createTournament = async () => {
    if (event) {
      setLoadingState((prevState) => ({ ...prevState, saveInProgress: true }));

      try {
        const created = await tcsApi.tournaments.create({
          tournamentPlatform: 'STARTGG',
          externalId: event.externalId,
          videoGame: 'SSBU',
          status: 'NOT_STARTED',
          ownerUserId: user.id ?? 0,
          name: tcTourneyName,
          imageUrl: ''
        });

        await refreshTournaments();
        navigate(`/tournaments/${created.id}`);
      } catch (err) {
        if (isAxiosError(err)) {
          const axiosErr = err as AxiosError;
          console.log(axiosErr?.response);
        }
        setCreateErrorText('Failed to add tournament');
      }
      setLoadingState((prevState) => ({ ...prevState, saveInProgress: false }));
    }
  };

  return  <Dialog fullWidth={true} onClose={onClose} open={open}>
    <DialogTitle>Add a Tournament</DialogTitle>
    <div style={{ marginBottom: 25}} >
      <Container>
        <Stack marginTop={2}>
          <TextField
            disabled={loadingState.lookupInProgress || loadingState.saveInProgress}
            id="outlined-basic"
            label="Enter a Start.gg event URL"
            placeholder="start.gg/tournaments/my-great-tournament/events/singles"
            variant="outlined"
            value={externalUrl}
            error={Boolean(lookupErrorText)}
            helperText={lookupErrorText}
            onChange={(event) => updateExternalUrlValue(event.currentTarget.value)}
          />
        </Stack>
        { loadingState.lookupInProgress && <LinearProgress style={{ marginTop: 5}}/> }
        <Button onClick={lookupTournament} disabled={!readyToLookup}>Lookup Event</Button>
        {
          event && <><Stack marginTop={2}>
            <Typography>
                            We found your event! Feel free to edit any details you&apos;d like below. When you&apos;re ready, go ahead and add it to our system.
            </Typography>
            <TextField
              disabled={loadingState.lookupInProgress || loadingState.saveInProgress}
              id="outlined-basic"
              label="What are you calling this tournament?"
              placeholder={event?.name}
              variant="outlined"
              value={tcTourneyName}
              error={Boolean(createErrorText)}
              helperText={createErrorText}
              onChange={(event) => setTcTourneyName(event.currentTarget.value)}
              sx={{ marginTop: 4}}
            />
          </Stack>
          { loadingState.saveInProgress && <LinearProgress style={{ marginTop: 5}}/> }
          <Button onClick={createTournament}>Add to Tourney Companion</Button>
          </>
        }

      </Container>
    </div>
  </Dialog>;
};

export default AddTournamentModal;
